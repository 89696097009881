import { apiRequest } from './apiRequest'
import { Nettskjema } from '../types/surveyModel'
import { XHR_REQUEST_TYPE, responseData } from '../types/main'

const getForm = async (id: string): Promise<Nettskjema | responseData | undefined> => {
  try {
    const result: Nettskjema = await apiRequest({
      route: '/api/nettskjema',
      method: XHR_REQUEST_TYPE.GET,
      query: { id: id },
    })

    return Promise.resolve(result)
  } catch (e) {
    console.log(e)
  }

  // return new Promise((resolve, reject) => {
  //   apiRequest({
  //     route: '/api/nettskjema',
  //     method: XHR_REQUEST_TYPE.GET,
  //     query: { id: id },
  //   })
  //     .then((result) => {
  //       resolve(result)
  //     })
  //     .catch((err) => reject(err))
  // })
}

export { getForm }
