<template>
  <div v-if="privacy" class="vue-modal grid justify-items-center">
    <div class="vue-modal-inner w-4/6 h-full">
      <div class="vue-modal-content h-2/6 flex flex-col justify-evenly">
        <!-- <slot></slot> -->
        <div
          class="v4v w-full relative grid justify-items-center text-lg flex flex-row cursor-pointer font-extralight hover:font-semibold"
          @click="close()"
        >
          <div style="padding: 0.9rem" class="mt-4 text-center"><p class="leading-normal text-base m-4">Verktøy for veiledning</p></div>
          <div class="absolute -top-2 right-1">x</div>
        </div>
        <div style="background-color: #ffb24d" class="w-full flex flex-row px-2 h-4 justify-between"></div>
        <div class="text-xl px-4" style="color: var(--texte_grey_black_title, rgba(15, 15, 15, 0.69))">
          <p class="text-center">{{ privacy.title }}</p>
        </div>
        <div class="w-5/6 self-center text-center break-normal" style="padding: 0.7rem; color: var(--texte_grey_black_title, rgba(15, 15, 15, 0.69))">
          <p v-html="privacy.subtitle"></p>
        </div>

        <div class="flex justify-center space-x-8">
          <router-link style="padding: 0.8rem" class="w-52 rounded-full text-white text-center bg-gray-700" to="/privacy">
            Show Privacy policy
          </router-link>
          <div class="h-full">
            <SlButton class="w-32 h-full" background-class="bg-amber-500" @click="DPlogin(false)">Logg inn </SlButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import SlButton from '@/components/base/SlButton.vue'
  import { baseUrl } from '@/constants'

  import { defineComponent } from 'vue'
  import { useCMSStore } from '@/store/useCMSStore'
  export default defineComponent({
    name: 'PrivacyPopup',
    components: {
      SlButton,
    },
    emits: ['close-policy'],
    setup(props, context) {
      const { getters: cmsGetters } = useCMSStore()

      function close() {
        context.emit('close-policy')
      }

      function DPlogin(testing = false, username = 'testuser') {
        localStorage.setItem('isConsented', 'true')
        let feideLoginUrl = `${baseUrl}/auth/dataporten/login`
        feideLoginUrl += `?device=webApp${testing ? `&testing=${username}` : ''}`
        window.location.href = feideLoginUrl
      }
      return { DPlogin, close, privacy: cmsGetters.privacy }
    },
  })
</script>
<style scoped>
  *,
  ::before,
  ::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .vue-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
  .vue-modal-inner {
    /* max-width: 500%; */
    margin: 30rem auto;
  }
  .vue-modal-content {
    position: relative;
    background-color: #fff;
    border: 1 px solid rgba(0, 0, 0, 0.3);
    background-clip: padding-box;
    /* padding: 1rem; */
    border-radius: 0.3rem;
  }
</style>
