<template>
  <div class="relative h-full w-full flex justify-center items-center">
    <div id="lollipop-chart"></div>
    <div v-if="studentsReview" class="mt-5 mr-5 legend0 text-white p-2 rounded-full">
      {{ studentsReview.title }}
    </div>

    <div v-if="teachersReview" class="mt-5 mr-5 legend1 text-white p-2 rounded-full">
      {{ teachersReview.title }}
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent, onMounted } from 'vue'
  import { setupLollipop } from '../graphs/lollipopGraph'
  import { useSurveyStore } from '@/store/useSurveyStore'

  interface iData {
    group: string
    value1: number
    value2: number
  }
  export default defineComponent({
    setup() {
      const { getters: surveyGetters } = useSurveyStore()

      const studentsReview = surveyGetters.studentsReview
      const teachersReview = surveyGetters.teachersReview
      const data: Array<iData> = []
      if (studentsReview.value) {
        studentsReview.value.review.forEach((value, key) => {
          if (teachersReview.value) {
            const va = teachersReview.value.review.get(key)
            data.push({
              group: key,
              value1: value, // student Review
              value2: va ? va : 0,
            })
          }
        })
      }
      onMounted(() => {
        setupLollipop('#lollipop-chart', data)
      })

      return {
        studentsReview,
        teachersReview,
        data,
      }
    },
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .legend0 {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #cc333f;
  }

  .legend1 {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #00a0b0;
  }
</style>
