<template>
  <div class="flex flex-col p-4 justify-center items-center h-screen">
    <!-- <p v-if="status.loading">Loading...</p>
    <p v-if="user.projects.length == 0">No interventions found</p>
    <SlButton
      v-if="hasMonitorRole"
      class="m-4 focus:outline-none"
      @click="monitor()"
      >Monitor</SlButton
    > -->
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import router from '../../router'

  import { useI18n } from 'vue-i18n'
  import { BrowserLanguageCodes } from '../../constants'
  import { LocalUser } from '../../types/main'
  import { useAppStore } from '../../store/useAppStore'
  import { useUserStore } from '../../store/useUserStore'
  import useDeviceService from '../../composition/useDevice'
  // import SlButton from '@/components/base/SlButton.vue'

  // This component completes setup of the app after login
  export default defineComponent({
    name: 'AfterLogin',
    components: {
      // SlButton,
    },
    setup() {
      const { locale } = useI18n({ useScope: 'global' })
      const { getters: appGetters, actions: appActions } = useAppStore()
      const { getters: userGetters, actions: userActions } = useUserStore()
      const { getters: deviceGetters, actions: deviceActions } = useDeviceService()

      const isMobileDevice: boolean = deviceGetters.deviceReady.value
      const isOnline: boolean = deviceGetters.deviceOnline.value
      const currentLocalUser: LocalUser | undefined = appGetters.currentLocalUser.value

      // // This should re-calculate when the user is loaded
      // const hasMonitorRole = computed(() => userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.monitor))

      // We have just fulfilled Sync sequence Stage A - Login. Moving on to the next sync steps..
      // NOTE: Refer to docs/syncing.md

      // Sync sequence Stage B. Load Data from server and/or disk
      // const getData = (): Promise<void> => {
      //   let userPromise: Promise<void>
      //   if (isMobileDevice && !isOnline && currentLocalUser) {
      //     userActions.setCordovaPath(currentLocalUser._id)
      //     userPromise = userActions.loadUser() // Load user from local disk
      //   } else {
      //     // Load user from server, select it (including user.participants) then save it locally
      //     // userPromise = userActions.getMyUser().then(() => {
      //     //   const user = userGetters.myUser.value
      //     //   // projectActions.clearProjects()
      //     //   // user.projects.forEach((p) => projectActions.addProject(p))
      //     //   return userActions.saveUser()
      //     // })
      //   }
      //   // return userPromise.then(() => {
      //   // const myUser = userGetters.myUser.value

      //   // // Get and Set the user's current Project from User store to where it's needed in other areas
      //   // const project = userGetters.selectedUserProject.value
      //   // if (project) {
      //   //   projectActions.selectProject(project)
      //   // }

      //   // Load the Participants for this user
      //   // 1. From server
      //   // 2. From disk (replacing any matches from server)
      //   // return participantActions.getParticipants().then(() => {
      //   //   return participantActions.loadParticipants(myUser).then(() => {
      //   //     return participantActions.loadTrackings(myUser)
      //   //   })
      //   // })
      //   // })
      // }

      // Sync sequence Stage B. Load Data from server and/or disk
      const getData = (): Promise<void> => {
        let userPromise: Promise<void>
        if (isMobileDevice && !isOnline && currentLocalUser) {
          userActions.setCordovaPath(currentLocalUser._id)
          userPromise = userActions.loadUser() // Load user from local disk
        } else {
          // Load user from server, select it (including user.participants) then save it locally
          userPromise = userActions.getMyUser().then(() => {
            return userActions.saveUser()
          })
        }

        return userPromise.then(() => {
          const myUser = userGetters.myUser.value

          // Set the local language to user's language, unless 'system' then set default
          const languageCode = myUser.profile.language

          if (languageCode !== 'system') {
            locale.value = languageCode as string
            appActions.setLanguageCode(locale.value as BrowserLanguageCodes)
          } else if (Object.keys(BrowserLanguageCodes).some((language) => language == locale.value)) {
            // NOTE: Activate supported languages in constants.ts
            const lang: BrowserLanguageCodes = locale.value as BrowserLanguageCodes
            appActions.setLanguageCode(lang)
          } else appActions.setLanguageCode(BrowserLanguageCodes['nb-NO'])

          // Load the Participants for this user
          // 1. From server
          // 2. From disk (replacing any matches from server)
          // return participantActions.getParticipants().then(() => {
          //   const ps = participantGetters.participants.value.filter((p) => p.dbRefs.user1 === myUser._id || p.dbRefs.user2 === myUser._id)
          //   userActions.setParticipants(ps)
          //   return participantActions.loadParticipants(myUser).then(() => {
          //     return participantActions.loadTrackings(myUser)
          //   })
          // })
        })
      }

      // Synce sequence Stage E. and F. Post Trackings and Update Participants
      // syncParticipant() also saves to disk Stage D.
      // const updateParticipants = (): Promise<void> => {
      //   // We don't need to wait for trackings post to complete, just let in run
      //   participantActions.sendTrackings()
      //   if (isOnline && isMobileDevice) {
      //     // Synchronise any locally stored Participant data with the server
      //     return participantActions.syncParticipant(true).then()
      //   } else return Promise.resolve()
      // }

      // getData()
      //   .then(() => {
      //     /*return updateParticipants().then(() => {
      //       return deviceActions.loadMediaCache().then(() => {
      //         /* if (participantGetters.selectedParticipant._id)
      //         router.push('/dashboard')
      //       else router.push('/participants')

      //       })
      //     })*/
      //     router.push('/participants')
      //   })
      //   .catch(() => router.push('/'))

      getData()
        .then(() => {
          // return updateParticipants().then(() => {
          return deviceActions.loadMediaCache().then(() => {
            router.push('/dashboard')
          })
          // })
        })
        .catch((error) => {
          console.log(error)
          router.push('/')
        })

      return {
        // Computed
        // status: appGetters.status,
        user: userGetters.selectedUser,
        // hasMonitorRole,

        // Methods
        // monitor: () => router.push('/monitor'),
      }
    },
  })
</script>

<style scoped></style>
