<template>
  <div class="grid grid-rows-1 justify-items-center" style="font-family: 'Inter', sans-serif">
    <div id="graph" class="lg:w-5/6 xl:w-3/6 m-8 flex flex-col leading-relaxed">
      <p class="text-lg">Lærerolle:</p>
      <span class="text-lg font-light"
        >Tilnærminger til lærerrollen: De grønne stolpene viser hvordan dine valg sammenfaller med teori om ulike
        <span class="text-lg cursor-pointer text-blue-400 hover:text-blue-500 fontStyle" @click="showComponent('leader')"> Læreroller </span>
        . De blå stolpene viser gjennomsnitt for andre som har brukt simulatoren. Les om de ulike lærerollene og bruk guiden for selvrefleksjon for å
        tenke gjennom din tilnærming til lærerrollen med utgangpunkt til disse
        <span class="text-lg cursor-pointer text-blue-400 hover:text-blue-500" @click="showComponent('reflection')"> resultatene </span>.
      </span>
      <div id="my_dataviz"></div>

      <div>
        <p class="text-lg">Figuren under viser hvordan du har utforsket ulike valg i simulatoren.</p>
        <div class="border-2 rounded-lg text-zinc-800 w-5/6 h-3/6">
          <div class="bg-slate-600 h-16 text-lg font-light px-2 grid grid-cols-7 justify-items-center text-white">
            <p class="my-4">Gruppe</p>

            <div v-for="(path, pathKey) in dataPath" :key="pathKey" class="">
              <div v-if="!pathKey.includes('\\u002e')" class="my-4">Scenario-{{ pathKey }}</div>
            </div>
          </div>
          <div
            v-for="(leadership, key, index) in leaderships"
            :key="key"
            class="grid grid-cols-7 h-16 border-1 border-inherit justify-items-center"
            :class="index % 2 == 0 ? 'bg-slate-200' : 'bg-slate-300'"
          >
            <p class="">{{ leadership }}</p>
            <div v-for="(path, pathKey) in dataPath" :key="pathKey" class="">
              <p v-if="path == key" class="mx-2">
                <img class="mx-12 my-8 h-6" src="@/assets/icons/svg/blackcheck.svg" />
                <!-- <img class="mx-12 h-4 -mt-4" src="@/assets/icons/svg/checked.svg" /> -->
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent, onMounted } from 'vue'
  import { setupBarGraph } from '../graphs/barGraph'
  import { useUserStore } from '@/store/useUserStore'

  import html2canvas from 'html2canvas'
  import { TRACKING_TYPE, SPECIAL_REQUEST_TYPE, SpecialRequestDataV4V } from '@/types/main'
  import { LEADERSHIP_TYPES } from '@/types/itemModel'
  import router from '@/router'

  export default defineComponent({
    setup(props, context) {
      // let show = ref('')
      const { getters: userGetters, actions: userActions } = useUserStore()

      const dataPath = userGetters.selectedTracking.value?.details
      if (dataPath) {
        Object.entries(dataPath).forEach(([key, value]) => {
          if (!key.includes('\\u002e')) {
            value = value.slice(0, 1).toUpperCase()
            dataPath ? (dataPath[key] = value) : ''
          }
        })
      }
      onMounted(() => {
        const t = userGetters.selectedTracking

        if (t.value) {
          userActions
            .getSpecialRequestV4V(userGetters.selectedUser.value._id, t.value?.oid, SPECIAL_REQUEST_TYPE.aggregate)
            .then((data: SpecialRequestDataV4V) => {
              setupBarGraph('#my_dataviz', data)
            })
            .catch((err) => console.log(err))
        }
      })
      const home = () => {
        router.push('/dashboard')
      }
      const capture = () => {
        const resultProfile = document.getElementById('graph')
        if (resultProfile instanceof HTMLElement) {
          html2canvas(resultProfile, {
            backgroundColor: 'white',
          }).then(function (canvas) {
            saveAs(canvas.toDataURL(), 'Network.png')
          })
        }

        function saveAs(uri: string, filename: string) {
          const link = document.createElement('a')

          if (typeof link.download === 'string') {
            link.href = uri
            link.download = filename

            //Firefox requires the link to be in the body
            document.body.appendChild(link)

            //simulate click
            link.click()

            //remove the link when done
            document.body.removeChild(link)
          } else {
            window.open(uri)
          }
        }
      }
      const showComponent = (component: string) => {
        context.emit('update-show', component)
      }
      return {
        dataPath,
        leaderships: LEADERSHIP_TYPES,
        //Methods
        home,
        showComponent,
        capture,
      }
    },
  })
</script>
<style>
  .fontStyle {
    font-family: 'Inter', sans-serif;
  }
</style>
