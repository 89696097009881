<template>
  <div class="relative">
    <div class="inline-block w-64 absolute z-50">
      <select
        v-model="chartType"
        class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
        @change="chooseChart"
      >
        <option value="spider-chart">Spider Chart</option>
        <option value="lollipop-chart">Lollipop Chart</option>
      </select>
      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
    <!-- Graph view -->
    <div class="h-screen">
      <RadarGraph v-if="chartType === 'spider-chart'"></RadarGraph>
      <LollipopGraph v-if="chartType === 'lollipop-chart'"> </LollipopGraph>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import RadarGraph from '@/components/RadarGraphComponent.vue'
  import LollipopGraph from '@/components/LollipopGraphComponent.vue'

  export default defineComponent({
    components: {
      RadarGraph,
      LollipopGraph,
    },
    setup() {
      const chartType = ref('spider-chart')

      const chooseChart = () => {
        // console.log(chartType.value)
      }
      return {
        chooseChart,
        chartType,
      }
    },
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
</style>
