<template>
  <div>
    <div class="grid grid-rows-1 relative justify-items-center fontLight m-12">
      <ReflectionItem
        v-if="selectedReflection"
        id="selectedReflection"
        class="justify-self-center lg:w-3/6 xl:w-3/6"
        :reflection="selectedReflection"
        :mode="mode"
        @close-popup="closePopup()"
      ></ReflectionItem>

      <img
        v-if="!selectedReflection && !title.subHeading"
        class="rounded-full h-6 absolute top-0 left-96"
        src="@/assets/angles-left-solid.svg"
        @click="close"
      />
      <div v-else-if="!selectedReflection && title.subHeading" class="relative grid grid-rows-1 justify-items-center">
        <p class="w-4/6 text-center m-2 leading-normal text-2xl font-semibold">{{ title.subHeading }}</p>
        <img v-if="!selectedReflection" class="rounded-full h-4 absolute top-0 left-40" src="@/assets/angles-left-solid.svg" @click="close" />
      </div>

      <div v-for="(reflection, index) in Array.from(reflections.values())" :key="index" class="w-3/6 grid justify-items-center">
        <div
          v-if="selectedReflection !== reflection.header"
          class="w-5/6 h-80 rounded-lg my-12 grid grid-cols-2 border-2"
          style="background-color: #ecebff"
        >
          <div class="flex flex-col justify-around w-4/6 mx-4">
            <p class="text-left text-2xl text-black whitespace-pre-line">{{ reflection.header }}</p>

            <SlButton
              class="h-8 mt-5 w-28 focus:outline-none justify-self-end button"
              :custom-width="'8rem'"
              @click=";(selectedReflection = reflection.header), scroll()"
            >
              Les mer
            </SlButton>
          </div>
          <div class="flex items-center justify-center rounded-r-lg" :style="getColor(index)">
            <img :class="index == 3 ? 'w-3/6 h-64' : 'w-5/6'" class="justify-self-end h-36 rounded-lg" :src="getImage(index)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent, PropType, ref /*, ComputedRef*/ } from 'vue'
  import SlButton from '@/components/base/SlButton.vue'
  import { useCMSStore } from '../store/useCMSStore'
  import ReflectionItem from './ReflectionItem.vue'
  interface ReflectionTitle {
    heading: string
    subHeading: string
  }
  export default defineComponent({
    components: { SlButton, ReflectionItem },

    props: {
      mode: { type: String, required: true },
      title: { type: Object as PropType<ReflectionTitle>, required: true },
    },
    emits: ['close-popup'],
    setup(props, context) {
      const { getters: cmsGetters } = useCMSStore()
      const selectedReflection = ref('')
      const expand = ref(false)
      const getColor = (index: number) => {
        switch (index) {
          case 0:
            return 'background-color: #70fab7;'
          case 1:
            return 'background-color: #8ad4ff;'
          case 2:
            return 'background-color: #ffdd57;'
          case 3:
            return 'background-color: #fd8204;'

          case 4:
            return 'background-color: #144578;'
        }
      }
      const getImage = (index: number) => {
        switch (index) {
          case 0:
            return '/images/lightball2.svg'
          case 1:
            return '/images/Peer.svg'

          case 2:
            return '/images/Mentor.svg'
          case 3:
            return '/images/Oppstart.png'
          case 4:
            return '/images/Hands.svg'
        }
      }
      const scroll = () => {
        const ele = document.getElementById('selectedReflection')

        window.scrollTo(0, 0)
        if (ele !== null) {
          ele.scrollIntoView()
        }
      }
      const closePopup = () => {
        selectedReflection.value = ''
        expand.value = !expand.value
      }
      function close() {
        context.emit('close-popup')
      }
      return {
        reflections: cmsGetters.reflections,
        selectedReflection,
        expand,
        close,
        closePopup,
        //Methods
        getColor,
        getImage,
        scroll,
      }
    },
  })
</script>
<style scoped>
  .self {
    background-color: #70fab7;
    overflow: visible;
  }
  .button {
    background-color: var(--Canvas_Bluelight, #1a6899);
  }
  .fontLight {
    letter-spacing: 0px;
    font-weight: 300;
    font-family: 'Inter-Light', 'Inter', sans-serif;
  }
</style>
