import { computed, ref, Ref, ComputedRef } from 'vue'
import { CordovaPathName } from '../constants'
import { apiRequest } from '../api/apiRequest'
import moment from 'moment'
// import { hasMinimumRole } from '../utilities'
import {
  User,
  UserData,
  LocalUser,
  Tracking,
  TrackingData,
  responseData,
  APIRequestPayload,
  XHR_REQUEST_TYPE,
  CordovaData,
  TRACKING_TYPE,
  SPECIAL_REQUEST_TYPE,
  SpecialRequestDataV4V,
  SimulatorResult,
  SimulatorResultData,
  codeData,
  XHR_CONTENT_TYPE,
} from '../types/main'
import { getForm } from '../api/nettskjemaService'
import { useAppStore } from './useAppStore'
// import { useParticipantStore } from './useParticipantStore'
import useDeviceService from '../composition/useDevice'

const { getters: deviceGetters, actions: deviceActions } = useDeviceService()
const { actions: appActions } = useAppStore()
// const { actions: participantActions } = useParticipantStore()

// ------------  State (internal) --------------
interface Payload {
  user_id: string
  trackingOID: string
  requestType: SPECIAL_REQUEST_TYPE
  trackingType: TRACKING_TYPE
}
interface State {
  myUser: User
  trackings: Map<string, Tracking>
  selectedUser: User
  allUsers: User[]
  cordovaPath: string[]
  selectedTracking: SimulatorResult | undefined
  showResult: boolean
}
const state: Ref<State> = ref({
  myUser: new User(), // The actual logged in User. Initialised after successful login
  selectedUser: new User(), // This user is the model for making changes to a User
  allUsers: [], // All users in the system, for admins
  cordovaPath: [],
  trackings: new Map(),
  groupMode: false,
  showResult: false,
  selectedTracking: undefined,
})
// ------------  Internal functions ------------

async function fetchMyUser(): Promise<UserData> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/user',
  }
  return apiRequest<UserData>(payload)
}

async function fetchAllUsers(): Promise<UserData[]> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/users',
  }
  return apiRequest<UserData[]>(payload)
}

async function sendUpdateUser(user: User): Promise<UserData> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.PUT,
    credentials: true,
    route: '/api/user',
    body: user,
  }
  return apiRequest(payload)
}

async function fetchSpecialRequestV4V(query: Payload): Promise<SpecialRequestDataV4V> {
  const payload: APIRequestPayload = {
    method: XHR_REQUEST_TYPE.GET,
    credentials: true,
    route: '/api/tracking/specialv4v',
    // params: { 'trackingOID':query.trackingOID,'requestType': query.requestType, 'trackingType':query.trackingType, 'user_id':query.user_id },
    query: { trackingOID: query.trackingOID, requestType: query.requestType, trackingType: query.trackingType, user_id: query.user_id },
  }

  return apiRequest<SpecialRequestDataV4V>(payload)
}
// Syncronise users with the server
async function syncUsers(users: User[]): Promise<void> {
  while (users.length > 0) {
    const u = users.pop()
    if (u) {
      const payload: APIRequestPayload = {
        method: XHR_REQUEST_TYPE.PUT,
        credentials: true,
        route: '/api/user',
        body: u.asPOJO(),
      }
      let userData
      try {
        userData = await apiRequest<UserData>(payload)
      } catch (error: any) {
        console.log(`Error syncing users: ${error.toString()}`)
      }
      // After updating at server, update locally
      // const localU = state.value.allUsers.find((u) => u._id == u._id)
      // if (localU && userData) {
      //   localU.update(userData)
      //   localU.updateProgress(userData)
      // }
      if (userData) {
        console.log(userData)
        state.value.selectedUser.update(userData)
        state.value.selectedUser.updateProgress(userData)
      }
    }
  }
  return Promise.resolve()
}
// Sent currently loaded Trackings to the server if not already sent
// We can only send trackings owned by the current user, as we must know the Participant IDs
async function sendTrackings(): Promise<void> {
  function loadTrackingFile(path: string[], fileName: string): Promise<BlobPart | void> {
    const cd: CordovaData = new CordovaData({
      fileName,
      readFile: true,
      asText: true,
      asJSON: true,
      path,
    })
    console.log(cd, 'cd')
    return deviceActions.loadFromStorage<BlobPart>(cd).then((blob) => blob)
  }

  if (!deviceGetters.deviceOnline.value) return Promise.resolve()

  // Iterate over all tracking selecting those that are not marked 'serverSynced'
  const it = state.value.trackings.values()
  let t = it.next()

  // Using async-await, multiple tracking posts should be sent in series
  while (!t.done) {
    const tracking: Tracking = t.value
    if (!tracking.serverSynced) {
      const path = [CordovaPathName.users, tracking.userID]
      // let trackingAudioFile
      // let trackingVideoFile
      // if (tracking.audioFile) trackingAudioFile = await loadTrackingFile(path, tracking.audioFile)
      // if (tracking.videoFile) trackingVideoFile = await loadTrackingFile(path, tracking.videoFile)

      const formData = new FormData()

      // const data = JSON.stringify(tracking.asPOJO())
      // formData.append('data', data)

      // if (trackingAudioFile) {
      //   const blob = new Blob([trackingAudioFile], { type: 'audio/mp4' })
      //   formData.append('audio', blob)
      // }
      // if (trackingVideoFile) {
      //   const blob = new Blob([trackingVideoFile], { type: 'video/mp4' })
      //   formData.append('video', blob)
      // }

      const payload: APIRequestPayload = {
        method: XHR_REQUEST_TYPE.POST,
        credentials: true,
        route: '/api/tracking',
        body: tracking.asPOJO(),
      }

      // Wait for the request to return and see that it succeeded
      let trackingData
      try {
        trackingData = await apiRequest<TrackingData>(payload)
      } catch (error: any) {
        console.log(`Error posting tracking data: ${error.toString()}`)
      }
      if (trackingData) tracking.serverSynced = !!trackingData.serverSynced
      else console.log(`Tracking POST failed! Tracking ID: ${tracking.itemID}`)
    }
    t = it.next()
  }
  return actions.saveTrackings()
}

// ------------  Getters --------------

// Once a reactive getter has been gotten by a component
// we cannot overwrite its instance here in the store - but we can write to its children reactively
// Complex objects provided by a getter here should be represented by a Class and also have an update() function
interface Getters {
  myUser: ComputedRef<User>
  selectedUser: ComputedRef<User>
  allUsers: ComputedRef<User[]>

  trackings: ComputedRef<Tracking[]>
  selectedTracking: ComputedRef<State['selectedTracking']>
  showResult: ComputedRef<boolean>
}
const getters = {
  get myUser(): ComputedRef<User> {
    return computed(() => state.value.myUser) // This is the current logged in user and should not change during app usage
  },
  get selectedUser(): ComputedRef<User> {
    return computed(() => state.value.selectedUser) // This is the 'currently selected' user and can change, must change by calling User.update()
  },
  get allUsers(): ComputedRef<User[]> {
    return computed(() => state.value.allUsers) // Unlikely to change during app usage, but ok as long as the array itself is not overwitten
  },

  get trackings(): ComputedRef<Tracking[]> {
    return computed(() => Array.from(state.value.trackings.values()))
  },
  get selectedTracking(): ComputedRef<State['selectedTracking']> {
    return computed(() => state.value.selectedTracking)
  },
  get showResult(): ComputedRef<boolean> {
    return computed(() => state.value.showResult)
  },
}

// ------------  Actions --------------
interface Actions {
  // hasMinimumRole: (user: User, role: USER_ROLE) => boolean
  selectUser: (user: User) => void

  // Server
  getMyUser: () => Promise<void>
  getAllUsers: () => Promise<void>
  updateUser: (user: User) => Promise<void>
  addCode: (value: codeData) => Promise<void>

  // Disk
  setCordovaPath: (userID: string) => void
  loadUser: () => Promise<void>
  saveUser: () => Promise<void>
  // get info from Nettskejma
  getUserDetail: () => Promise<void>
  setSelectedTracking: (tracking: SimulatorResult) => Promise<void>
  commitNewTracking: (tracking: Tracking) => void
  updateTrackings: () => Promise<void>
  clearTrackings: () => Promise<void>
  clearResult: () => Promise<void>
  getSpecialRequestV4V: (user_id: string, trackingOID: string, requestType: SPECIAL_REQUEST_TYPE) => Promise<SpecialRequestDataV4V>
  completeProgressForItem: (itemId: string, parentId: string) => number
  syncUser: (updateAll?: boolean, user?: User) => Promise<void>
  sendTrackings: () => Promise<void>
  saveTrackings: () => Promise<void>
}
const actions = {
  // Retrieve from server the user details (called after login when online & not mobile)
  getMyUser: async function (): Promise<void> {
    appActions.setLoading(true)
    const response: UserData = await fetchMyUser()
    state.value.myUser.update(response)
    state.value.selectedUser.update(state.value.myUser)
    state.value.cordovaPath = [CordovaPathName.users, state.value.myUser._id]
    // participantActions.setParticipants(state.value.myUser.participants)
    const newLocalUser: LocalUser = {
      _id: state.value.myUser._id,
      name: state.value.myUser.profile.fullName,
      lastLogin: new Date(),
      jwt: localStorage.getItem('jwt') || '',
      pin: '',
      selected: true,
    }
    appActions.setCurrentLocalUser(newLocalUser)
    appActions.setLoading(false)

    return Promise.resolve()
  },

  // Add a new Tracking for this participant
  commitNewTracking: function (tracking: Tracking): void {
    console.log(tracking.oid)
    state.value.trackings.set(tracking.oid, tracking)
    console.log(state.value.trackings)
    state.value.selectedTracking = state.value.trackings.get(tracking.oid) as SimulatorResult
  },

  updateTrackings: async function (): Promise<void> {
    console.log(state.value.trackings)
    state.value.trackings.set(state.value.selectedTracking?.oid as string, state.value.selectedTracking as Tracking)
    state.value.showResult = true
  },

  clearTrackings: async function (): Promise<void> {
    state.value.trackings = new Map()
    state.value.selectedTracking = undefined
  },
  clearResult: async function (): Promise<void> {
    state.value.showResult = false
  },
  setSelectedTracking: async function (tracking: Tracking): Promise<void> {
    state.value.selectedTracking = new SimulatorResult(state.value.trackings.get(tracking.oid) as SimulatorResultData)
    state.value.showResult = true
  },
  //NOTE: This is specific to v4v

  getSpecialRequestV4V: async function (user_id: string, trackingOID: string, requestType: SPECIAL_REQUEST_TYPE): Promise<SpecialRequestDataV4V> {
    const query: Payload = {
      user_id: user_id,
      trackingOID: trackingOID,
      requestType: requestType,
      trackingType: TRACKING_TYPE.v4v,
    }
    const response: SpecialRequestDataV4V = await fetchSpecialRequestV4V(query)
    console.log(response)
    if (requestType == SPECIAL_REQUEST_TYPE.successresults)
      Object.values(response.results).forEach((i) => {
        i.created = moment(i.created).format('LLL')
        state.value.trackings.set(i.oid, i)
      })
    return {
      data1: response.data1,
      data2: response.data2,
      results: response.results,
    }
  },
  // Returns current number of completions for this item
  completeProgressForItem: function (itemId: string, parentId: string): number {
    let completions = 0
    if (state.value.selectedUser) completions = state.value.selectedUser.completeProgress(itemId, parentId)
    console.log(state.value.selectedUser, 'to check progress')
    return completions
  },
  // Save updated data to server and disk including synchronising Progress
  // Given participant, selected participant, or all Participants if updateAll == true
  // After the server response, local participant is updated by syncParticipants()
  syncUser(updateAll = false, user?: User): Promise<void> {
    let us: User[] = []
    if (updateAll) us = Array.from(state.value.allUsers.values())
    else if (user) us.push(user)
    else if (state.value.selectedUser) us.push(state.value.selectedUser)
    if (deviceGetters.deviceOnline) {
      // return syncUsers(us).then(() => this.saveParticipants(updateAll))
      return syncUsers(us).then(() => this.saveUser())
    }
    return Promise.resolve()
  },
  sendTrackings,
  // Retrieve from server a listing of all users
  getAllUsers: async function (): Promise<void> {
    appActions.setLoading(true)
    const response: UserData[] = await fetchAllUsers()
    const users = response.map((u: UserData) => new User(u))
    state.value.allUsers = users
    appActions.setLoading(false)
  },
  // Update with new code for the selected user
  addCode: async function (code: codeData): Promise<void> {
    console.log(code)
    state.value.selectedUser.codes.push(code)
    return Promise.resolve()
  },
  // Update a given user at server, and locally if it exists in allUsers
  updateUser: async function (user: User): Promise<void> {
    const response: UserData = await sendUpdateUser(user)

    state.value.myUser.update(response)
    state.value.selectedUser.update(state.value.myUser)
    // const modifiedUser = new User(response)
    // console.log(modifiedUser)
    // // Also update the user in local list
    // // const modifiedUser = state.value.allUsers.find((u) => u._id === user._id)
    // if (modifiedUser) modifiedUser.update(user)
    return Promise.resolve()
  },

  // // Check that the selected user has at least the role requested
  // // Direct reference to (utility function)
  // hasMinimumRole,

  selectUser: function (user: User) {
    const u = state.value.allUsers.find((us) => us._id === user._id)
    if (u) {
      state.value.selectedUser = u
    }
  },
  setCordovaPath: function (userID: string): void {
    state.value.cordovaPath = [CordovaPathName.users, userID]
  },
  loadUser: function (): Promise<void> {
    const cd: CordovaData = new CordovaData({
      fileName: 'user.json',
      readFile: true,
      asText: true,
      asJSON: true,
      path: state.value.cordovaPath,
    })
    return new Promise((resolve) => {
      deviceActions.loadFromStorage<UserData>(cd).then((data) => {
        if (data) {
          state.value.myUser.update(data)
          state.value.selectedUser.update(state.value.myUser)
          // participantActions.setParticipants(state.value.myUser.participants)
          resolve()
        }
      })
    })
  },
  saveUser: function (): Promise<void> {
    const cd: CordovaData = new CordovaData({
      fileName: 'user.json',
      data: state.value.myUser.asPOJO(),
      asText: true,
      asJSON: true,
      path: state.value.cordovaPath,
    })
    return deviceActions.saveToStorage(cd)
  },
  // Get all available pins for each teacher Student
  getUserDetail: async (): Promise<void> => {
    const allCodes: responseData = (await getForm('')) as responseData
    state.value.selectedUser.updateResponse(allCodes)

    return Promise.resolve()
  },
  saveTrackings: async function (): Promise<void> {
    console.log('trackings')
    // Convert Map to Object keyed by Participant ID
    const trackingsByUser: Record<string, unknown[]> = {}
    state.value.trackings.forEach((t) => {
      if (t.userID) {
        trackingsByUser[t.userID] = []
        if (t && t.asPOJO) trackingsByUser[t.userID].push(t.asPOJO)
      }
    })
    // Save each list of trackings to their user's folder
    for (const [uID, trackings] of Object.entries(trackingsByUser)) {
      const cd: CordovaData = new CordovaData({
        fileName: 'trackings.json',
        data: trackings,
        asText: true,
        asJSON: true,
        path: [CordovaPathName.users, uID],
      })
      await deviceActions.saveToStorage(cd)
    }
  },
}

interface ServiceInterface {
  actions: Actions
  getters: Getters
}
// This defines the interface used externally
export function useUserStore(): ServiceInterface {
  return {
    getters,
    actions,
  }
}

export type UserStoreType = ReturnType<typeof useUserStore>
// export const UserKey: InjectionKey<UseUser> = Symbol('UseUser')
