<template class="leadership-font p-12">
  <div class="grid justify-items-center relative fontLight m-12">
    <div class="flex justify-center">
      <div v-for="(quote, index) in leaderships.get(selectedLeadership)?.quotes" :key="index" class="w-full flex justify-center">
        <div v-if="quote.quotetitle !== ''" class="h-64 w-60 quote text-center space-x-4 mx-2">
          <p class="m-2 h-40 w-52 break-normal overscroll-y-contain overflow-auto text-sm">{{ quote.quotetitle }}</p>
        </div>
        <!-- truncate hover:overscroll-y-contain hover:overflow-auto -->
      </div>
    </div>
    <Leadership
      v-if="selectedLeadership && expand == true"
      id="selectedLeadership"
      class="justify-self-center w-3/5 m-6"
      :leadership="selectedLeadership"
      :mode="mode"
      @close-popup="closePopup()"
    ></Leadership>
    <img
      v-if="!selectedLeadership && !title.subHeading && mode != PROJECT_NAME.survey"
      class="rounded-full h-6 absolute top-0 left-96"
      src="@/assets/angles-left-solid.svg"
      @click="close"
    />
    <div
      v-else-if="mode === PROJECT_NAME.survey || (mode != PROJECT_NAME.survey && selectedLeadership == '' && title.subHeading)"
      class="relative grid justify-items-center"
    >
      <p class="w-4/6 leading-normal text-2xl font-semibold">
        {{ title.subHeading }}
      </p>
      <img v-if="!selectedLeadership" class="rounded-full h-6 absolute top-0 left-40" src="@/assets/angles-left-solid.svg" @click="close" />
    </div>

    <div class="justify-self-center text-left w-3/5 leading-normal text"></div>

    <div v-if="expand == false && mode === PROJECT_NAME.simulator" class="justify-self-center grid grid-rows-2 grid-flow-col mt-8 gap-3">
      <div v-for="(leadership, index) in leadershipKeys" :key="index">
        <div class="rounded-lg overflow-visible text-white w-72 h-96 py-8 grid grid-rows-3 grid-flow-col place-items-center" :class="leadership">
          <div class="h-32 w-32 mb-8">
            <p class="text-2xl mt-4">Lærerolle</p>
            <img class="rounded-full" :src="`/images/${leadership}.svg`" />
          </div>

          <div v-if="selectedLeadership !== leadership" class="row-start-3 row-end-4 row-span-2">
            <div class="flex flex-col justify-center">
              <p class="text-2xl my-6">{{ leadership }}</p>
              <SlButton class="mb-4 focus:outline-none place-self-center" :class="leadership + 'Button'" @click="readMore(leadership)">
                Les mer
              </SlButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="expand == false && mode == PROJECT_NAME.survey" class="grid grid-flow-col grid-cols-4 grid-rows-2 gap-x-2 mt-8 gap-y-4">
      <div v-for="(leadership, index) in leadershipKeys" :key="index">
        <div class="rounded-lg overflow-visible overflow-auto overscroll-auto text-white pt-4 bg-cyan-700 w-72 h-80">
          <div class="">
            <div class="flex flex-col justify-evenly">
              <p class="text-2xl">{{ leadership }}</p>
              <SlButton
                v-if="selectedLeadership !== leadership"
                class="my-24 focus:outline-none h-8 place-self-center buttonBackground"
                :text-class="'text-black'"
                :custom-width="'8rem'"
                @click="readMore(leadership)"
              >
                Les mer
              </SlButton>
            </div>
          </div>
          <div
            v-if="selectedLeadership === leadership && flip == true"
            class="row-start-1 row-end-4 relative h-56 mt-4 mx-2 overscroll-y-contain overflow-auto"
            @click="flipCard()"
          >
            <p class="" v-html="leadershipContent?.introduction"></p>
            <p class="" v-html="leadershipContent?.content"></p>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="expand == false && mode === PROJECT_NAME.videotool" class="justify-self-center grid grid-flow-col grid-cols-2 mt-8 gap-3">
      <div v-for="(leadership, index) in leadershipKeys" :key="index">
        <div class="rounded-lg overflow-visible text-white w-80 h-96 pt-32 bg-cyan-700">
          <div v-if="selectedLeadership !== leadership" class="row-start-2 row-end-4 row-span-2">
            <div class="flex flex-col justify-center">
              <p class="text-2xl mx-4">{{ leadership }}</p>
              <SlButton
                class="mb-8 my-16 focus:outline-none place-self-center buttonBackground"
                :text-class="'text-black'"
                :custom-width="'8rem'"
                @click="readMore(leadership)"
              >
                Les mer
              </SlButton>
            </div>
          </div>
          <div v-if="selectedLeadership === leadership && flip == true" class="row-start-1 row-end-4 relative">
            <p class="absolute -top-8 right-2" @click="flipCard()">X</p>
            <p class="" v-html="leadershipContent?.introduction"></p>
            <p class="" v-html="leadershipContent?.content"></p>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="expand == false && mode === PROJECT_NAME.guides" class="justify-self-center grid grid-rows-2 mt-8 grid-flow-col mt-8 gap-3">
      <div v-for="(leadership, index) in leadershipKeys" :key="index">
        <div
          class="rounded-lg overflow-visible text-white w-72 h-96 py-8 grid grid-rows-3 grid-flow-col place-items-center"
          :class="GUIDES[leadership as keyof typeof GUIDES]"
        >
          <div class="h-32 w-32 py-8 flex flex-col justify-evenly">
            <div :class="GUIDES[leadership as keyof typeof GUIDES]==GUIDES['Styringsdokumenter om nyutdannede som ressurs']?'bg-white m-6':''">
              <img class="rounded-full" :src="getImage(GUIDES[leadership as keyof typeof GUIDES])" />
            </div>
          </div>

          <div v-if="selectedLeadership !== leadership" class="row-start-2 row-end-4 row-span-2">
            <div class="flex flex-col justify-center mx-2">
              <p class="text-xl my-10">{{ leadership }}</p>
              <SlButton
                class="focus:outline-none place-self-center"
                :class="GUIDES[leadership as keyof typeof GUIDES] + 'Button'"
                :custom-width="'8rem'"
                @click="readMore(leadership)"
              >
                Les mer
              </SlButton>
            </div>
          </div>
          <div v-if="selectedLeadership === leadership && flip == true" class="row-start-1 row-end-4 relative">
            <p class="absolute -top-8 right-2" @click="flipCard()">X</p>
            <p class="" v-html="leadershipContent?.introduction"></p>
            <p class="" v-html="leadershipContent?.content"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import SlButton from '@/components/base/SlButton.vue'

  import Leadership from './LeadershipItem.vue'
  import { useCMSStore } from '../store/useCMSStore'

  import { PROJECT_NAME, GUIDES } from '@/constants'
  import { defineComponent, ref, PropType, computed } from 'vue'
  import Autoritær from '@/assets/Autoritær.svg'
  import Autoritativ from '@/assets/Autoritativ.svg'

  import Demokratisk from '@/assets/Demokratisk.svg'
  import Ettergivende from '@/assets/Ettergivende.svg'
  import ManagementDocumentNewGraduate from '@/assets/ManagementDocumentNewGraduate.svg'
  import Competance from '@/assets/Competance.svg'

  interface LeadershipTitle {
    heading: string
    subHeading: string
  }
  export default defineComponent({
    name: 'LeadershipsComponent',
    components: { SlButton, Leadership },
    props: {
      mode: { type: String, required: true },
      title: { type: Object as PropType<LeadershipTitle>, required: true },
    },
    emits: ['close-popup', 'update-show'],
    setup(props, context) {
      const { getters: cmsGetters } = useCMSStore()

      const selectedLeadership = ref('')
      const expand = ref(false)
      const flip = ref(false)
      const forward = () => {
        if (props.mode == 'v4v') context.emit('update-show')
      }
      const getImage = (leadership: string) => {
        return `/images/${leadership}.svg`
      }
      const scroll = () => {
        const ele = document.getElementById('selectedLeadership')
        if (ele !== null) ele.scrollIntoView()
      }
      const closePopup = () => {
        selectedLeadership.value = ''
        expand.value = !expand.value
      }
      const readMore = (leadership: string) => {
        selectedLeadership.value = leadership
        scroll()
        if (props.mode == PROJECT_NAME.survey) {
          flip.value = true
        } else expand.value = !expand.value
      }
      const flipCard = () => {
        flip.value = false
        selectedLeadership.value = ''
      }
      function close() {
        context.emit('close-popup')
      }
      return {
        PROJECT_NAME,
        GUIDES,
        leadershipKeys: computed(() => {
          return Array.from(cmsGetters.leaderships.value.keys())
        }),
        leadershipContent: computed(() => cmsGetters.leaderships.value.get(selectedLeadership.value)),
        selectedLeadership,
        expand,
        leaderships: computed(() => cmsGetters.leaderships.value),
        flip,
        forward,
        getImage,
        //Methods
        scroll,
        closePopup,
        readMore,
        flipCard,
        Autoritær,
        Autoritativ,
        Demokratisk,
        Ettergivende,
        ManagementDocumentNewGraduate,
        Competance,
        close,
      }
    },
  })
</script>

<style scoped>
  .fontLight {
    letter-spacing: 0px;
    text-align: center;
    font-weight: 300;
    font-family: 'Inter-Light', 'Inter', sans-serif;
  }
  .quote {
    background-image: url('@/assets/icons/svg/SpeechBubble.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .font {
    letter-spacing: 0px;
    text-align: center;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
  }
  .Autoritær {
    background-color: #144578;
  }
  .Demokratisk {
    background-color: #fd8204;
  }
  .Autoritativ {
    background-color: #feef35;
    color: var(--texte_grey_black, #535050);
  }
  .Ettergivende {
    background-color: #05d394;
  }
  .AutoritærButton {
    background-color: #0d3258;
  }
  .DemokratiskButton {
    background-color: #e67301;
  }
  .AutoritativButton {
    background-color: #f0dd0b;
  }
  .EttergivendeButton {
    background-color: #08af7c;
  }
  .buttonBackground {
    background-color: var(--greylightblue_, #cfd2dd);
  }
  .Schoolculture {
    background-color: #05d394;
  }
  .SchoolcultureButton {
    background-color: #08af7c;
  }
  .Competance {
    background-color: #f7e922;
  }
  .CompetanceButton {
    background-color: #f0dd0b;
  }
  .ManagementDocumentProfessional {
    background-color: #fd8204;
  }
  .ManagementDocumentProfessionalButton {
    background-color: #e67301;
  }
  .ManagementDocumentNewGraduate {
    background-color: #144578;
  }
  .ManagementDocumentNewGraduateButton {
    background-color: #0d3258;
  }

  .leadership-font {
    word-wrap: break-word;
    word-break: break-word;
    font-family: 'Inter-Medium', 'Inter', sans-serif;
    font-size: 16px;
  }
  .text {
    overflow: visible;
    white-space: pre;
    letter-spacing: 0px;
    line-height: 1.6;
    color: #000000;
    font-weight: 500;
    font-family: 'Inter-Medium', 'Inter', sans-serif;
    font-size: 22px;
  }
</style>
