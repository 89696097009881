import { Nettskjema, Reviews } from '@/types/surveyModel'
import { ref, Ref, computed, ComputedRef } from 'vue'

// state
interface State {
  studentsReview: Reviews | undefined
  teachersReview: Reviews | undefined
}

const state: Ref<State> = ref({
  studentsReview: undefined,
  teachersReview: undefined,
})

//Getters
interface Getters {
  studentsReview: ComputedRef<State['studentsReview']>
  teachersReview: ComputedRef<State['teachersReview']>
}

const getters = {
  get studentsReview(): ComputedRef<State['studentsReview']> {
    return computed(() => state.value.studentsReview)
  },
  get teachersReview(): ComputedRef<State['teachersReview']> {
    return computed(() => state.value.teachersReview)
  },
}

//Actions
interface Actions {
  populateStore: (nettskjemaData: Nettskjema) => Promise<void>
}
const actions = {
  populateStore: function (nettskjemaData: Nettskjema): Promise<void> {
    return new Promise((resolve) => {
      state.value.studentsReview = new Reviews(nettskjemaData.studentsReview)
      state.value.teachersReview = new Reviews(nettskjemaData.teachersReview)
      resolve()
    })
  },
}

// This defines the interface used externally
interface ServiceInterface {
  actions: Actions
  getters: Getters
}
export function useSurveyStore(): ServiceInterface {
  return {
    getters,
    actions,
  }
}

export type SurveyStoreType = ReturnType<typeof useSurveyStore>
