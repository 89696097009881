<template>
  <div class="absolute top-0 left-0 w-full h-1/6 z-10 flex flex-wrap items-center sm:justify-center">
    <div class="mx-auto w-11/12 sm:w-1/2 h-4/6 p-2 rounded-full border-2 bg-white">
      <div class="m-2 text-center">
        {{ message }}
      </div>

      <SlButton class="col-start-2 my-2 mr-2" :background-class="'bg-green-400'" @click="closeFeedback">OK</SlButton>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { useAppStore } from '../store/useAppStore'
  import { useI18n } from 'vue-i18n'
  import SlButton from './base/SlButton.vue'

  const messages = {}

  export default defineComponent({
    name: 'FeedbackOverlay',
    components: {
      SlButton,
    },
    props: {
      message: {
        type: String,
        default: '',
      },
    },
    setup() {
      const appStore = useAppStore()
      const { t } = useI18n({ messages })
      /*  onMounted(() => {
      setTimeout(() => {
        appStore.actions.setFeedbackMessage('')
      }, 3000)
    }) */
      const closeFeedback = () => {
        appStore.actions.setFeedbackMessage('')
      }
      return { t, closeFeedback }
    },
  })
</script>
