/* eslint-disable */
import * as d from 'd3'
import * as scale from 'd3-scale'
let d3 = Object.assign({}, d, scale)

import { LEADERSHIP_TYPES } from '@/types/itemModel'

const setupBarGraph = (id, data) => {
  console.log(data)
  const dataMap = new Map()
  //Initiallise a dataMap
  Object.keys(LEADERSHIP_TYPES).forEach((group) =>
    dataMap.set(group, {
      group: group,
      value1: 0,
      value2: 0,
    }),
  )
  // update value in map
  const updateValue = (item, val) => {
    if (dataMap.has(item.group)) {
      let temp = dataMap.get(item.group)
      if (val == 1) temp.value1 = item.value
      if (val == 2) temp.value2 = item.value

      dataMap.set(item.group, temp)
    }
  }

  data.data1.forEach((item) => updateValue(item, 1))
  data.data2.forEach((item) => updateValue(item, 2))

  let results = Array.from(dataMap.values())

  results = results.map((i) => {
    console.log(LEADERSHIP_TYPES[i.group])
    i.group = LEADERSHIP_TYPES[i.group]
    return i
  })

  // ----------------
  // Create a tooltip
  // ----------------
  var tooltip = d3
    .select(id)
    .append('div')
    .style('opacity', 0)
    .attr('class', 'tooltip')
    // .style('background-color', 'white')
    .style('border', 'solid')
    .style('border-width', '1px')
    .style('border-radius', '5px')
    .style('padding', '5px')

  var container = d3.select(id),
    width = 600,
    height = 500,
    margin = { top: 80, right: 80, bottom: 30, left: 50 },
    barPadding = 0.4,
    axisTicks = { qty: 5, outerSize: 0, dateFormat: '%m-%d' }

  var svg = container
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`)

  var xScale0 = d3
    .scaleBand()
    .range([0, width - margin.left - margin.right])
    .padding(barPadding)
  var xScale1 = d3.scaleBand()
  var yScale = d3.scaleLinear().range([height - margin.top - margin.bottom, 0])

  var xAxis = d3.axisBottom(xScale0).tickSizeOuter(axisTicks.outerSize)
  // var xAxis = d3.svg.axis().scale(xScale0).tickSizeOuter(axisTicks.outerSize)
  var yAxis = d3.axisLeft(yScale).ticks(axisTicks.qty).tickSizeOuter(axisTicks.outerSize)

  xScale0.domain(results.map((d) => d.group))
  xScale1.domain(['value1', 'value2']).range([0, xScale0.bandwidth()])
  yScale.domain([0, d3.max(results, (d) => (d.value1 > d.value2 ? d.value1 : d.value2))])

  var group = svg
    .selectAll('.group')
    .data(results)
    .enter()
    .append('g')
    .attr('class', 'group')
    .attr('transform', (d) => `translate(${xScale0(d.group)},0)`)

  // Three function that change the tooltip when user hover / move / leave a cell
  var p = d3.select(id).data(results)

  var mouseover = function (d) {
    tooltip.style('left', d.x + 'px')
    tooltip.style('top', d.y + 'px')
    tooltip.style('display', 'inline-block')
    tooltip.style('opacity', '0.9')

    var elements = document.querySelectorAll(':hover')
    var l = elements.length - 1
    var elementData = elements[l].__data__

    tooltip.html(elementData.group + '<br>' + elementData[this.classList[1]])
    d3.selectAll('.tooltip')
      .data(data)
      .enter()
      .append('div')
      .attr('class', 'tooltip')
      .attr('left', (d) => x(d.data.name))
    // .style('opacity', 0)
    // #05d394;
    d3.select(this)
      .attr('fill', '#F8786B')
      // .style('opacity', '0.7')
      .style('stroke', 'Black')
      .style('stroke-width', '1.8px')
      .style('stroke-opacity', '1')
      .html(elementData.group + '<br>' + elementData[this.classList[1]])
  }
  var mousemove = function (d) {
    tooltip
      .style('left', d3.mouse(this)[0] + 90 + 'px') // It is important to put the +90: other wise the tooltip is exactly where the point is an it creates a weird effect
      .style('top', d3.mouse(this)[1] + 'px')
    d3.select(this).transition().duration(250).style('stroke-opacity', '0')
  }
  var mouseleave = function (d) {
    tooltip.style('opacity', 0)
  }

  /* Add value1 bars */
  group
    .selectAll('.bar.value1')
    .data((d) => [d])
    .enter()
    .append('rect')
    .attr('class', 'bar value1')
    .style('fill', '#05d394')
    .attr('x', (d) => xScale1(d.value1))
    .attr('y', (d) => yScale(d.value1))
    .attr('width', xScale1.bandwidth())
    .attr('height', (d) => {
      return height - margin.top - margin.bottom - yScale(d.value1)
    })
    .on('mouseover', mouseover)
    .on('mousemove', mousemove)
    .on('mouseleave', mouseleave)

  /* Add value2 bars */
  group
    .selectAll('.bar.value2')
    .data((d) => [d])
    .enter()
    .append('rect')
    .attr('class', 'bar value2')
    .style('fill', '#144578')
    .attr('x', (d) => xScale1('value2'))
    .attr('y', (d) => yScale(d.value2))
    .attr('width', xScale1.bandwidth())
    .attr('height', (d) => {
      return height - margin.top - margin.bottom - yScale(d.value2)
    })
    .on('mouseover', mouseover)
    .on('mousemove', mousemove)
    .on('mouseleave', mouseleave)

  // Add the X Axis
  svg
    .append('g')
    .attr('class', 'x axis')
    .attr('transform', `translate(0,${height - margin.top - margin.bottom})`)
    .call(xAxis)

  // Add the Y Axis
  svg.append('g').attr('class', 'y axis').call(yAxis)

  var options = ['dine resultater', 'gj. snitt av andres resultater']

  var legend = svg
    .selectAll('.legend')
    .data(options.slice())
    .enter()
    .append('g')
    .attr('class', 'legend')
    .attr('transform', function (d, i) {
      return 'translate(0,' + i * 20 + ')'
    })

  legend
    .append('rect')
    .attr('x', width - 70)
    .attr('width', 30)
    .attr('height', 20)
    .attr('y', -70)
    .style('fill', function (d) {
      return d == 'dine resultater' ? '#05d394' : '#144578'
    })
    .on('click', function (d) {})

  legend
    .append('text')
    .attr('x', width - 80)
    .attr('y', -60)
    .attr('dy', '.35em')
    .style('text-anchor', 'end')
    .text(function (d) {
      return d
    })
}

export { setupBarGraph }
