// When TypeScript can extend enums, this can be made generic..
export enum LEADERSHIP_TYPES {
  A = 'Autoritær',
  M = 'Autoritativ',
  D = 'Demokratisk',
  E = 'Ettergivende',
}
export enum TEACHING_TYPES {
  care = 'Care',
  confer = 'Confer',
  captivate = 'Captivate',
  clarify = 'Clarify',
  consolidate = 'Consolidate',
  challenge = 'Challenge',
  classroom_management = 'Classroom management',
}
export enum GUIDES {
  'Guide for  valg av videoklipp' = 'Guide for  valg av videoklipp',
  'Guide for  opptak av video' = 'Guide for  opptak av video',
}
export interface TabData {
  heading: string
  subHeading: string
}

export interface LandingPageData {
  id: string
  title: {
    iv: string
  }
  subtitle: {
    iv: string
  }
  secondSubtitle: {
    iv: string
  }
}
export class LandingPage {
  id: string
  title: string
  subtitle: string
  secondSubtitle: string

  constructor(spec: LandingPageData, id: string) {
    this.id = id
    this.title = spec.title ? spec.title.iv : ''
    this.subtitle = spec.subtitle ? spec.subtitle.iv : ''
    this.secondSubtitle = spec.secondSubtitle ? spec.secondSubtitle.iv : ''
  }
}
export interface PrivacyData {
  id: string
  title: {
    iv: string
  }
  subtitle: {
    iv: string
  }
  content: {
    iv: string
  }
}
export class Privacy {
  id: string
  title: string
  subtitle: string
  content: string

  constructor(spec: PrivacyData, id: string) {
    this.id = id
    this.title = spec.title ? spec.title.iv : ''
    this.subtitle = spec.subtitle ? spec.subtitle.iv : ''
    this.content = spec.content ? spec.content.iv : ''
  }
}
export interface DashboardData {
  id: string
  title: {
    iv: string
  }
  subtitle: {
    iv: string
  }
  tabs: {
    iv: TabData[]
  }
  notes: {
    iv: string
  }
}
export class Dashboard {
  id: string
  title: string
  subtitle: string
  tabs: {
    iv: TabData[]
  }
  notes: string

  constructor(spec: DashboardData, id: string) {
    this.tabs = {
      iv: [],
    }
    this.id = id
    this.title = spec.title ? spec.title.iv : ''
    this.subtitle = spec.subtitle ? spec.subtitle.iv : ''
    spec.tabs.iv.forEach((i) => {
      this.tabs.iv.push({
        heading: i.heading ? i.heading : '',
        subHeading: i.subHeading ? i.subHeading : '',
      })
    })
    this.notes = spec.notes ? spec.notes.iv : ''
  }
}
interface Stages {
  stageItem: string
  stageItemSubtitle: string
}
export interface ReflectionData {
  id: string
  index: {
    iv: string
  }
  header: {
    iv: string
  }
  subheader: {
    iv: string
  }
  content: {
    iv: string
  }
  question: {
    iv: [{ questionItem: string }]
  }
  fillup: {
    iv: [{ fillupItem: string }]
  }
  stages: {
    iv: Stages[]
  }
}
export class Reflection {
  id: string
  header: string
  index: string
  subheader: string
  content: string
  question: [{ questionItem: string }]
  fillup: [{ fillupItem: string }]
  stages: Stages[]

  constructor(spec: ReflectionData) {
    this.id = spec.id
    this.header = spec.header ? spec.header.iv : ''
    this.index = spec.index ? spec.index.iv : ''
    this.subheader = spec.subheader ? spec.subheader.iv : ''
    this.content = spec.content ? spec.content.iv : ''
    this.question = spec.question ? spec.question.iv : [{ questionItem: '' }]
    this.fillup = spec.fillup ? spec.fillup.iv : [{ fillupItem: '' }]
    this.stages = spec.stages ? spec.stages.iv : []
  }
}

export interface LeadershipData {
  id: string
  header: {
    iv: string
  }
  introduction: {
    iv: string
  }
  content: {
    iv: string
  }
  icon: {
    iv: [{ url: string }]
  }
  quotes: {
    iv: [{ quotetitle: string }]
  }
}
export class Leadership {
  id: string
  header: string
  introduction: string
  content: string
  icon: [{ url: string }]
  quotes: [{ quotetitle: string }]
  flip?: boolean // front-end value to flip cards
  constructor(spec: LeadershipData) {
    this.id = spec.id
    this.header = spec.header ? spec.header.iv : ''
    this.introduction = spec.introduction ? spec.introduction.iv : ''
    this.content = spec.content ? spec.content.iv : ''
    this.icon = spec.icon ? spec.icon.iv : [{ url: '' }]
    this.quotes = spec.quotes ? spec.quotes.iv : [{ quotetitle: '' }]
  }
}

export interface OptionData {
  type: string
  text: string
  audioFile: [{ url: string }]
  next: [{ id: string }]
}
export interface ItemData {
  id: string
  indexID: {
    iv: string
  }
  header: {
    nb_NO: string
  }
  headerAudio: {
    nb_NO: [{ url: string }]
  }
  subHeader: {
    nb_NO: string
  }
  subHeaderAudio: { url: string }

  image: {
    nb_NO: [{ url: string }]
  }
  question: {
    nb_NO: string
  }
  previousItem: {
    iv: [{ id: string }]
  }
  options: {
    nb_NO: OptionData[]
  }
}

export interface Option {
  optionId: string
  type: string
  text: string
  audioFile: [{ url: string }]

  next: [{ id: string }]
}
export class Item {
  id: string
  indexID: string
  header: string
  headerAudio: {
    nb_NO: [{ url: string }]
  }
  subHeader: string
  subHeaderAudio: { url: string }
  image: {
    nb_NO: [{ url: string }]
  }
  question: string
  previousItem: string

  options: {
    nb_NO: Option[]
  }
  constructor(spec: ItemData, id: string) {
    this.options = {
      nb_NO: [],
    }
    this.id = id
    this.indexID = spec.indexID ? spec.indexID.iv : ''
    this.header = spec.header ? spec.header.nb_NO : ''
    this.headerAudio = spec.headerAudio ? spec.headerAudio : { nb_NO: [{ url: '' }] }
    this.subHeader = spec.subHeader ? spec.subHeader.nb_NO : ''
    this.subHeaderAudio = spec.subHeaderAudio
    this.image = spec.image ? spec.image : { nb_NO: [{ url: '' }] }
    this.previousItem = ''
    this.question = spec.question ? spec.question.nb_NO : ''
    spec.options.nb_NO.forEach((i) => {
      this.options.nb_NO.push({
        optionId: i.type ? spec.indexID + i.type : '',
        type: i.type ? i.type : '',
        text: i.text ? i.text : '',
        audioFile: i.audioFile ? i.audioFile : [{ url: '' }],
        next: i.next ? i.next : [{ id: '' }],
      })
    })
  }
}
