import { cmsRequest } from '@/api/cmsService'
import { PROJECT_NAME, BLCToSLC, BrowserLanguageCodes } from '@/constants'
import { ref, Ref, computed, ComputedRef } from 'vue'
import { CmsGQLQuery } from '@/types/main'
// import { useProjectStore } from '@/store/useProjectStore'
import {
  Item,
  ItemData,
  LeadershipData,
  Leadership,
  Reflection,
  ReflectionData,
  DashboardData,
  Dashboard,
  PrivacyData,
  Privacy,
  LandingPage,
  LandingPageData,
} from '@/types/itemModel'

// Query strings for each Project
import itemQuery from '@/store/graphql/item.gql'
import leadershipQuery from '@/store/graphql/leadership.gql'
import reflectionQuery from '@/store/graphql/reflectionTool.gql'
import dashboardQuery from '@/store/graphql/dashboard.gql'
import privacyQuery from '@/store/graphql/privacy.gql'
import LandingPageQuery from '@/store/graphql/landingPage.gql'
// import videoReflectionQuery from '../graphql/videoReflection.gql'
import { useAppStore } from '@/store/useAppStore'
// import { useUserStore } from '@/store/useUserStore'
// const projectStore = useProjectStore()
const { getters: appGetters, actions: appActions } = useAppStore()
const NumberOfRecords = 150
// State
interface State {
  project: PROJECT_NAME

  selectedItem: Item | undefined
  items: Map<string, Item>
  leaderships: Map<string, Leadership>
  reflections: Map<string, Reflection>
  dashboard: Dashboard | undefined
  privacy: Privacy | undefined
  landingPage: LandingPage | undefined
}

const state: Ref<State> = ref({
  project: PROJECT_NAME.simulator,
  selectedItem: undefined,
  items: new Map(),
  leaderships: new Map(),
  reflections: new Map(),
  dashboard: undefined,
  privacy: undefined,
  landingPage: undefined,
})
// Internal Functions
async function fetchItem(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  // const project = projectStore.getters.selectedProject.value
  const query = itemQuery.loc.source.body as string

  // const squidexLanguage = BLCToSLC[language]
  const variables = {
    // __language: squidexLanguage,
    __setTop: NumberOfRecords,
  }

  return cmsRequest(state.value.project, query, variables, language)
}
async function fetchLeaderships(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  // const project = projectStore.getters.selectedProject.value
  const query = leadershipQuery.loc.source.body as string
  // const squidexLanguage = BLCToSLC[language]
  const variables = {
    __setTop: NumberOfRecords,
    // __language: squidexLanguage,
  }

  return cmsRequest(state.value.project, query, variables, language)
}
async function fetchReflections(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  const query = reflectionQuery.loc.source.body as string
  const toolType = state.value.project
  // toolType == PROJECT_NAME.videotool
  //   ? (query = videoReflectionQuery as string)
  //   : (query = reflectionQuery as string)
  // const squidexLanguage = BLCToSLC[language]
  const variables = {
    // __language: squidexLanguage,
    __setTop: NumberOfRecords,
  }

  return cmsRequest(toolType, query, variables, language)
}

async function fetchPrivacy(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  const query = privacyQuery.loc.source.body as string
  // const squidexLanguage = BLCToSLC[language]
  const variables = {
    // __language: squidexLanguage,
    __setTop: NumberOfRecords,
  }

  return cmsRequest(PROJECT_NAME.simulator, query, variables, language)
}

async function fetchLandingPage(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  const query = LandingPageQuery.loc.source.body as string
  // const squidexLanguage = BLCToSLC[language]
  const variables = {
    // __language: squidexLanguage,
    __setTop: NumberOfRecords,
  }

  return cmsRequest(PROJECT_NAME.simulator, query, variables, language)
}
async function fetchDashboard(language: BrowserLanguageCodes): Promise<CmsGQLQuery> {
  const query = dashboardQuery.loc.source.body as string
  // const squidexLanguage = BLCToSLC[language]
  const variables = {
    // __language: squidexLanguage,
    __setTop: NumberOfRecords,
  }

  return cmsRequest(state.value.project, query, variables, language)
}
//Getters
interface Getters {
  items: ComputedRef<Item[]>
  selectedItem: ComputedRef<State['selectedItem']>
  leaderships: ComputedRef<State['leaderships']>
  reflections: ComputedRef<State['reflections']>
  project: ComputedRef<string>
  dashboard: ComputedRef<State['dashboard']>
  privacy: ComputedRef<State['privacy']>
  landingPage: ComputedRef<State['landingPage']>
}
const getters = {
  get items(): ComputedRef<Item[]> {
    return computed(() => Array.from(state.value.items.values()))
  },
  get selectedItem(): ComputedRef<State['selectedItem']> {
    return computed(() => state.value.selectedItem)
  },
  get leaderships(): ComputedRef<State['leaderships']> {
    return computed(() => state.value.leaderships)
  },
  get reflections(): ComputedRef<State['reflections']> {
    return computed(() => state.value.reflections)
  },
  get project(): ComputedRef<string> {
    return computed(() => state.value.project)
  },

  get dashboard(): ComputedRef<State['dashboard']> {
    return computed(() => state.value.dashboard)
  },
  get privacy(): ComputedRef<State['privacy']> {
    return computed(() => state.value.privacy)
  },
  get landingPage(): ComputedRef<State['landingPage']> {
    return computed(() => state.value.landingPage)
  },
}
//Actions
interface Actions {
  getItem: () => Promise<void>
  selectItem: (index: string) => void
  updatePreviousItem: (nextId: string) => void
  getLeaderships: () => Promise<void>
  getReflections: () => Promise<void>
  getDashboardContents: () => Promise<void>
  getPrivacyContents: () => Promise<void>
  setselectedProject: (mode: PROJECT_NAME) => Promise<void>
  getLandingPageContents: () => Promise<void>
}

// get all Scenarios data  from CMS
const actions = {
  getLeaderships: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const response: CmsGQLQuery = await fetchLeaderships(language)
    state.value.leaderships.clear()
    if (response.data) {
      response.data?.leaderships?.forEach((i) => {
        if (i.data && i.id) {
          const d = i.data as unknown as LeadershipData
          state.value.leaderships.set(d.header.iv, new Leadership(d))
        }
      })
    }
  },
  getReflections: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const response: CmsGQLQuery = await fetchReflections(language)
    state.value.reflections.clear()
    if (response.data) {
      response.data?.reflections?.sort((a, b) => {
        const x = a.data as unknown as ReflectionData
        const y = b.data as unknown as ReflectionData
        return parseInt(x.index.iv) - parseInt(y.index.iv)
      })
      response.data?.reflections?.forEach((i) => {
        if (i.data && i.id) {
          const d = i.data as unknown as ReflectionData
          state.value.reflections.set(d.header.iv, new Reflection(d))
        }
      })
    }
  },
  getDashboardContents: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const response: CmsGQLQuery = await fetchDashboard(language)
    if (response.data?.dashboard) {
      state.value.dashboard = undefined // clear contents
      const i = response.data.dashboard[0]
      if (i.data && i.id) {
        const d = i.data as unknown as DashboardData
        state.value.dashboard = new Dashboard(d, i.id)
      }
    }
  },
  getPrivacyContents: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const response: CmsGQLQuery = await fetchPrivacy(language)
    if (response.data?.privacy) {
      const i = response.data.privacy[0]
      if (i.data && i.id) {
        const d = i.data as unknown as PrivacyData
        state.value.privacy = new Privacy(d, i.id)
      }
    }
  },
  getLandingPageContents: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const response: CmsGQLQuery = await fetchLandingPage(language)
    if (response.data?.landing) {
      const i = response.data.landing[0]
      if (i.data && i.id) {
        const d = i.data as unknown as LandingPageData
        state.value.landingPage = new LandingPage(d, i.id)
      }
    }
  },

  getItem: async function (): Promise<void> {
    const language = appGetters.languageCode.value || 'nn-NO'
    const response: CmsGQLQuery = await fetchItem(language)
    if (response.data) {
      response.data?.items?.forEach((i) => {
        if (i.data && i.id) {
          const d = i.data as unknown as ItemData
          state.value.items.set(i.id, new Item(d, i.id))
        }
      })
    } else {
      const error = 'Item query contains no records'
      console.log(error)
    }

    return Promise.resolve()
  },

  // Called from the Items.vue, when changing the displayed question
  selectItem: function (index: string): void {
    if (index == '0') {
      const items: Array<Item> = Array.from(state.value.items.values())
      state.value.selectedItem = items.find((item) => item.indexID == '1')
    } else {
      if (state.value.items.get(index)) state.value.selectedItem = state.value.items.get(index)
    }
  },

  //Update previous item
  updatePreviousItem: function (nextId: string): void {
    if (state.value.selectedItem) {
      if (state.value.items.get(nextId)) {
        const nextItem: Item = state.value.items.get(nextId) as Item

        nextItem.previousItem = state.value.selectedItem.id
        state.value.items.set(nextId, nextItem)
      }
    }
  },
  // Replace the current list of participants with another
  setselectedProject: function (mode: PROJECT_NAME): Promise<void> {
    if (mode) {
      state.value.project = mode
    }
    return Promise.resolve()
  },
}
// This defines the interface used externally
interface ServiceInterface {
  actions: Actions
  getters: Getters
}
export function useCMSStore(): ServiceInterface {
  return {
    getters,
    actions,
  }
}

export type CMSStoreType = ReturnType<typeof useCMSStore>
