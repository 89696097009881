import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Landing from '../views/landing/Landing.vue'
import AfterLogin from '@/views/landing/AfterLogin.vue'
import Dashboard from '@/views/dashboard/DashboardView.vue'
import Tool from '@/views/tool/ToolView.vue'
import SurveyProfile from '@/views/survey/SurveyProfile.vue'
import SurveyResponse from '@/views/survey/SurveyResponse.vue'
import SimulationItems from '@/views/simulator/SimulationItems.vue'
import PrivacyPolicy from '@/views/privacypolicy/PrivacyPolicy.vue'
import { useCMSStore } from '@/store/useCMSStore'
const { actions: cmsActions } = useCMSStore()
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Landing',
    component: Landing,
    beforeEnter(to, from, next) {
      cmsActions
        .getLandingPageContents()
        .then(() => next())
        .catch((error) => {
          console.log(error)
          router.push('/')
        })
    },
  },
  { path: '/index.html', redirect: '/' },
  {
    path: '/login',
    name: 'Login',
    component: AfterLogin,
  },
  {
    path: '/dashboard/',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/tool',
    name: 'tool',
    component: Tool,
  },
  {
    path: '/survey/profile',
    name: 'SurveyProfile',
    component: SurveyProfile,
  },
  {
    path: '/survey/response',
    name: 'SurveyResponse',
    component: SurveyResponse,
  },

  {
    path: '/simulator/items',
    name: 'SimulationItems',
    component: SimulationItems,
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
]
const routerType = createWebHistory(import.meta.env.BASE_URL)

const router = createRouter({
  history: routerType,
  routes,
})

router.beforeEach((to, from, next) => {
  const isSample = to.path.includes('/sample/')

  if (!(from.name || to.redirectedFrom?.name) && to.name !== 'Login' && to.name !== 'Landing' && to.name !== 'Admin Login' && !isSample) {
    next('/')
  } else next()
})

export default router
