<template>
  <div class="mb-4 bg-white overflow-y-scroll">
    <SlButton class="mb-8 focus:outline-none absolute right-2 top-2 bg-blue-600 text-white justify-self-end" :custom-width="'8rem'" @click="logout">
      Logout
    </SlButton>

    <div class="flex flex-col justify-center items-center">
      <div class="h-screen w-full mx-8 pb-96 frame place-self-center"></div>

      <div class="heading text-center w-5/6 text-5xl sm:text-3xl my-8">Velkommen til å ta i bruk våre verktøy for veiledning</div>
      <div class="subHeader text-base font-medium w-2/6 my-5 text-center">
        Verktøy for veiledning består av ulike verktøy organisert i flere pakker. Velg hvilken verktøypakke du vil jobbe med:
      </div>
      <div class="content-center grid grid-rows-2 h-4/12 grid-flow-col gap-3 my-4">
        <div v-for="(tool, index) in simulatorInfo" :key="index" class="">
          <div class="border border-current rounded-lg flex flex-col justify-between space-y-2" :class="[tool, 'h-full w-96']">
            <div class="w-full rounded-tl-lg rounded-tr-lg flex justify-center" :style="`${tool.color}`" :class="'h-24'">
              <div><img class="" :class="index == PROJECT_NAME.survey ? 'h-16 mt-10 w-24' : 'h-16 w-16 mt-8'" :src="`${tool.imgsrc}`" /></div>
            </div>
            <div :class="''" class="inline-block text text-base font-light mx-12">
              {{ tool.text }}
            </div>
            <div class="flex justify-center">
              <SlButton
                class="relative my-4 focus:outline-none bg-blue-600 text-white justify-self-end"
                :custom-width="'8rem'"
                :text-class="'buttonText'"
                @click="selectTool(index)"
              >
                {{ tool.title }}
              </SlButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent class="w-full footerBg mt-8 -mb-8"></FooterComponent>
  </div>
</template>
<script lang="ts">
  import { defineComponent, ref, onMounted } from 'vue'
  import SlButton from '@/components/base/SlButton.vue'
  import camera from '@/assets/camera-movie-light.svg'
  import { XHR_REQUEST_TYPE } from '@/types/main'
  import { apiRequest } from '@/api/apiRequest'
  import { useAppStore } from '@/store/useAppStore'
  import FooterComponent from '@/components/FooterComponent.vue'
  import { useCMSStore } from '@/store/useCMSStore'
  import useDeviceService from '@/composition/useDevice'
  import { PROJECT_NAME } from '@/constants'
  import router from '@/router'
  import lightball from '@/assets/lightball2.svg'
  import Peer from '@/assets/Peer.svg'
  import Mentor from '@/assets/Mentor.svg'
  import Top from '@/assets/Top.svg'
  interface TOOLINFO {
    title: string
    imgsrc: string
    color: string
    text: string
  }
  const { getters: appGetters, actions: appActions } = useAppStore()
  // const { getters: userGetters, actions: userActions } = useUserStore()
  const { actions: deviceActions } = useDeviceService()
  const { actions: cmsActions } = useCMSStore()

  export default defineComponent({
    name: 'DashboardView',
    components: { SlButton, FooterComponent },
    setup() {
      const selectLTI = (mode: string) => {
        console.log(mode)
      }
      const selectedTool = appGetters.selectedToolMode
      const rememberMe = ref(false)
      const simulatorInfo: Record<string, TOOLINFO> = {
        simulator: {
          title: 'Beslutningssimulator',
          imgsrc: lightball,
          color: 'background-color:#ffb24d',
          text: 'Innta rollen som lærer i 10A og ta valg i situasjonene som oppstår! Resultatet knytter valgene til teori om ulike tilnærminger til lærerrollen. Her finner du også guider for veiledning og selvrefleksjon, laget for å bidra til refleksjon om lærerrolle.',
        },
        survey: {
          title: 'Respons fra elever og egenvurdering',
          imgsrc: Peer,

          color: 'background-color:var(--Canvas_Bluelight, #1a6899)',
          text: 'Hva tenker elevene om undervisningen de mottar? Og hva tenker den som underviser? Med disse verktøyene innhentes og analyseres respons som utforskes i selvrefleksjon og veiledning.',
        },
        videotool: {
          title: 'Videoverktøy',
          imgsrc: camera,
          color: 'background-color:#ed9ef0',
          text: 'Å filme egen praksis kan bidra til utviklende refleksjon og diskusjoner om lærerrollen! Her finner du verktøy for å filme, bearbeide, analysere og diskutere lærerpraksis basert på film.',
        },
        guides: {
          title: 'Nyutdannede som ressurs',
          imgsrc: Mentor,
          color: 'background-color:#00cb8d',
          text: 'En nyutdannet er en ressurs for skolen sin! Men hva er de best på, og hvordan kan kompetansen deres komme hele skolen til gode? Her finner ledere, veiledere og nyutdannede verktøy som kan fremme hensiktsmessige strukturer for å ivareta nyutdannede på egen skole',
        },
      }

      onMounted(() => {
        cmsActions.getPrivacyContents()
      })
      const logout = () => {
        const completeLogout = () => {
          appActions.logout(rememberMe.value)

          router.push('/')
        }
        apiRequest({
          route: '/auth/logout',
          method: XHR_REQUEST_TYPE.GET,
          credentials: true,
        })
          .then(() => {
            completeLogout()
          })
          .catch((error) => {
            // Exchange was not accepted, clear the token and redirect to login page
            console.log(error)
            completeLogout()
          })
      }
      const selectTool = (index: string) => {
        appActions.setSelectTool(index).then(() => {
          router.push('/tool')
        })
      }
      deviceActions.saveMediaCache()
      return {
        //methods
        // getImage,
        selectLTI,
        selectedTool,
        simulatorInfo,
        Top,
        logout,
        selectTool,
        PROJECT_NAME,
      }
    },
  })
</script>
<style>
  .footerBg {
    background-color: #4e4d4b;
  }
  .heading {
    font-family: 'Inter-SemiBold', 'Inter', sans-serif;
    color: var(--texte_grey_black_title, rgba(15, 15, 15, 0.69));
  }
  .subHeader {
    font-family: 'Inter-Medium', 'Inter', sans-serif;
    color: var(--texte_grey_black, #535050);
  }
  .buttonText {
    font-family: 'Inter', sans-serif;
    color: #ffffff;
  }

  .button {
    background-color: var(--token-f2b667fb-deef-4e64-9211-374fa3f73688, #246bfd);
    border-radius: 46px;
  }
  .frame {
    background-image: url('../../assets/Top.svg');
    background-repeat: no-repeat;
    background-size: contain;

    height: 656px;
  }
  .text {
    font-weight: 300;
    font-family: 'Inter-Light', 'Inter', sans-serif;
    color: var(--texte_grey_black_title, rgba(15, 15, 15, 0.69));

    text-align: center;
  }
</style>
