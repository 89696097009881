<template>
  <div class="mt-4 bg-white h-screen">
    <div class="text-xs absolute top-1 right-0">NB: The app has been tested in chrome and firefox</div>
    <!-- Logo and introduction -->
    <div class="grid grid-rows-1 space-y-6 justify-items-center">
      <div class="v4v relative text-lg flex flex-row cursor-pointer font-extralight hover:font-semibold" @click="homePage">
        <div class="mt-8">Verktøy for veiledning</div>
      </div>
      <div class="lg:w-5/6 xl:w-3/6 mt-6">
        <p class="text-left textExtraLight leading-relaxed font-extralight text-3xl">Elevrespons og selvevaluering</p>
      </div>

      <div class="mt-2 lg:w-5/6 xl:w-3/6">
        <div class="flex flex-row space-x-12">
          <input
            v-model="inputName"
            type="String"
            class="border-2 h-12 text-center rounded-full"
            placeholder="Navn på klasse"
            @keyup.enter="generatePin"
          />

          <SlButton
            class="buttonbackgroundColor relative mb-4 focus:outline-none bg-blue-600 text-white justify-self-end"
            :custom-width="'8rem'"
            :text-class="'buttonText'"
            @click="generatePin"
          >
            Legg til klasse
          </SlButton>
          <div v-if="errorMessage" class="text-red-600">
            {{ errorMessage }}
          </div>
        </div>
      </div>

      <template v-if="theUser">
        <div v-for="(code, codeIndex) in theUser.codes" :key="codeIndex" class="lg:w-5/6 xl:w-3/6 flex flex-col">
          <div class="flex justify-between border-t-2 py-2 space-x-4">
            <div class="text-wrap textExtraLight font-light text-2xl mx-2">{{ code.name }} {{ code.code }}</div>
            <div class="flex justify-start flex-col text-left textLight text-sm font-light mx-4">
              <div class="flex justify-between space-x-4">
                <div
                  class="text-base fontStyle"
                  :class="
                    code.Sresponse && code.Sresponse.count as number > 0
                      ? 'text-gray-700 '
                      : 'text-white h-8   cursor-pointer flex items-center justify-center rounded-3xl px-4'
                  "
                  :style=" code.Sresponse && code.Sresponse.count as number  > 0 ? '' : 'background-color:  #ff85fb'"
                  @click="code.Sresponse && (code.Sresponse.count as number) > 0 ? '' : open(160171, code.code)"
                >
                  Selvevaluering
                </div>

                <!-- <div class="flex items-center justify-center space-x-2 h-8 w-12"> -->
                <div
                  class="flex items-center justify-center rounded-full h-5 w-5 0"
                  :class="code.Sresponse && code.Sresponse.count as number > 0 ? 'bg-green-400' : 'bg-gray-400'"
                >
                  <img class="h-3 w-3" src="@/assets/icons/svg/validate.svg" />
                </div>
                <div class="flex items-center justify-center cursor-pointer rounded-full h-5 w-5 bg-gray-400" @click="open(160171, code.code)">
                  <img class="h-3 w-3" src="@/assets/icons/svg/again.svg" />
                </div>
              </div>
              <div>
                antall:
                <span v-if="code.Tresponse?.count > 0">{{ code.Tresponse.count }}</span>
              </div>
              <div>
                Last changes:
                <p v-if="code.Tresponse?.count > 0">
                  {{ code.Tresponse.timeStamp }}
                </p>
              </div>
            </div>

            <div class="px-10 flex space-x-4">
              <div
                class="fontStyle h-10 w-32 rounded-3xl flex items-center justify-center text-white text-base cursor-pointer"
                style="background-color: #ff9d20"
                @click="open(160170, code.code)"
              >
                Invite
              </div>
              <div
                class="fontStyle h-10 w-32 leading-relaxed border flex items-center justify-center text-base border-gray-400 rounded-3xl cursor-pointer"
                :class="code.Sresponse?.count > 0 || code.Tresponse?.count > 0 ? 'opacity-100' : 'opacity-50'"
                @click="findForm(code)"
              >
                Se på respons
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref, onMounted } from 'vue'
  import { getForm } from '@/api/nettskjemaService'
  import router from '@/router'
  import SlButton from '@/components/base/SlButton.vue'
  import { useSurveyStore } from '../../store/useSurveyStore'
  import { useUserStore } from '@/store/useUserStore'
  import { /* User, */ codeData } from '@/types/main'
  // import { nettskjemaUrl } from '../../../../constants'
  // eslint-disable-next-line no-unused-vars
  import { Nettskjema } from '@/types/surveyModel'

  export default defineComponent({
    components: { SlButton },
    props: {
      msg: {
        type: String,
        default: 'Dashboard',
      },
    },
    setup(props) {
      const text = computed(() => props.msg)
      const { actions: userActions, getters: userGetters } = useUserStore()
      const { actions: surveyActions } = useSurveyStore()
      const inputModel = ref('')
      const inputName = ref('')
      const errorMessage = ref('')
      const iteration2 = ref(true)
      const showInput = ref(false)
      const theUser = userGetters.selectedUser

      onMounted(() => {
        if (userActions) {
          userActions.getUserDetail().catch((err) => {
            console.log(err)
          })
        }
      })

      /* Methods */
      const open = (formID: number, code: number) => {
        const nettskjemaUrl = 'https://nettskjema.no/a/'
        if (nettskjemaUrl) {
          const uri = `${nettskjemaUrl}${formID}?CBcode=${code}`
          console.log(uri, 'uri')
          window.open(uri)
        }
      }
      const findForm = (code: codeData) => {
        // if (!code.Sresponse || !code.Tresponse) {
        //   alert('Inadequate feedback')
        // } else {
        getForm(code.code.toString())
          .then((result: unknown) => {
            if (result) surveyActions.populateStore(result as Nettskjema).then(() => router.push('/survey/response'))
          })
          .catch((err: string) => {
            errorMessage.value = 'Du har tastet inn ugyldig ID eller server has not been whitelisted '
            console.log(err)
          })
        // }
      }
      /*Add a random Pin to user Info and  submit to nettskjema form * */
      const generatePin = () => {
        if (inputName.value.length == 0) {
          errorMessage.value = 'Du har ikke tastet inn ugyldig navn '
        } else {
          showInput.value = !showInput.value
          const value: codeData = {
            name: inputName.value,
            code: Math.floor(1000 + Math.random() * 9000),
            SUri: '',
            TUri: '',
            Sresponse: {
              count: 0,
              timeStamp: new Date(),
            },
            Tresponse: {
              count: 0,
              timeStamp: new Date(),
            },
          }
          console.log(value)
          userActions.addCode(value).then(() => {
            console.log(theUser.value)
            userActions
              .updateUser(theUser.value)
              .then(async () => {
                console.log(userGetters.myUser)
                if (userActions) {
                  userActions.getAllUsers().catch((err) => {
                    console.log(err)
                  })
                }
              })
              .catch((err: string) => {
                errorMessage.value = 'User not available '
                console.log(err)
              })
          })

          inputName.value = ''
          errorMessage.value = ''
        }
      }
      const homePage = () => {
        router.push('/tool')
      }
      return {
        text,
        inputModel,
        inputName,
        errorMessage,
        iteration2,
        // theUser: computed(() => userGetters.selectedUser.value),
        showInput,
        /*  Methods */
        findForm,
        generatePin,
        open,
        theUser,
        homePage,
      }
    },
    data: () => ({
      postHeader: [
        { header: 'Klasse', mode: false },
        { header: 'Pin', mode: false },
        { header: 'URL til elevene', mode: false },
        { header: 'Antall ', mode: false },
      ],
    }),
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .textExtraLight {
    font-weight: 200;
    font-family: 'Inter-ExtraLight', 'Inter', sans-serif;
  }
  .klasse {
    width: auto; /* 67px */
    height: auto; /* 35px */
    overflow: visible;
    white-space: pre;
    letter-spacing: 0px;
    line-height: 1.6;
    font-weight: 300;
    font-family: 'Inter-Light', 'Inter', sans-serif;
    font-size: 22px;
    color: #787878;
  }
  .buttonbackgroundColor {
    background-color: var(--Canvas_Bluelight, #1a6899);
  }
  .fontStyle {
    font-family: 'Inter', sans-serif;
  }
</style>
