<template>
  <div v-if="reflections" class="font-light light grid rounded-lg m-6">
    <div class="relative">
      <p class="text-3xl my-4 text-center">{{ reflections.header }}</p>
      <p class="cursor-pointer text-xl absolute top-1 right-4" @click="close">X</p>
    </div>
    <p v-if="reflections" class="text-left text-lg leading-normal">
      <span v-html="reflections.subheader"></span>
    </p>

    <!-- Guide -->
    <div
      v-if="(reflections && reflections.index == '2' && mode == PROJECT_NAME.simulator) || (mode == PROJECT_NAME.guides && reflections.index == '3')"
      class="justify-self-center my-12 grid grid-cols-3 gap-4 h-5/6"
    >
      <div v-for="(stage, index) in reflections.stages" :key="index" class="flex flex-col bg-white rounded-3xl w-64">
        <div :style="getColor(index)" class="h-48 flex items-center justify-center rounded-t-3xl">
          <img class="mx-12 h-24 -mt-4" :src="getImage(index)" />
        </div>
        <div class="border rounded-b-3xl h-96 grid justify-items-center" style="background-color: #f5f5f7">
          <p class="text text-red-500 text-base leading-normal mt-4">Steg {{ index + 1 }}.</p>
          <p class="text text-red-500 text-2xl leading-normal" v-html="stage.stageItem"></p>
          <p class="light text-base font-light leading-snug mx-1 text-center" v-html="stage.stageItemSubtitle"></p>
        </div>
      </div>
    </div>
    <div class="flex flex-row">
      <p class="leading-normal text-left m-4 text-lg">
        <span v-html="reflections.content"></span>
      </p>
    </div>
    <!-- Questions and exercises  -->
    <div v-if="reflections.question.length > 0" class="grid justify-items-center">
      <p class="text-2xl font-light leading-relaxed">Refleksjonsspørsmål</p>
      <div class="grid grid-cols-3 gap-2 mx-2 mt-2">
        <div v-for="(question, index) in reflections.question" :key="index" class="my-4">
          <div class="relative questionItem rounded-lg text-white py-10 w-full h-full">
            <div class="flex flex-row h-8 my-2 mx-4 absolute bulb lg:left-14 xl:left-12 -top-6 rounded-3xl">
              <div
                class="h-14 w-14 absolute -left-10 -top-3.5 flex items-center justify-center rounded-full"
                style="background-color: #564def; border: 2px solid #9f99fd"
              >
                <img class="h-10" src="@/assets/lightball2.svg" />
              </div>

              <div>
                <p class="h-2 px-12 py-1">
                  <span class="text-white text-sm text-center">{{ index + 1 }}</span>
                </p>
              </div>
            </div>

            <p class="mt-5 text-center">{{ question.questionItem }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Exercises  -->
    <div class="my-8 w-5/6">
      <div v-if="reflections.fillup.length > 0" class="">
        <p class="subheader text-xl font-medium leading-relaxed">Fullfør disse setningene skriftlig</p>
        <div class="my-4 grid grid-cols-1 gap-2">
          <div v-for="(item, index) in reflections.fillup" :key="index" class="">
            <div class="justify-self-center flex space-x-2 bg-white rounded-lg text-white light p-2">
              <div>
                <div class="h-14 w-14 flex items-center justify-center rounded-lg" style="background-color: #564def">
                  <img class="h-10" src="@/assets/lightball2.svg" />
                </div>
              </div>
              <p class="text-black">{{ item.fillupItem }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent, computed } from 'vue'
  import { useCMSStore } from '@/store/useCMSStore'
  import { PROJECT_NAME } from '@/constants'
  export default defineComponent({
    name: 'ReflectionItem',
    props: {
      reflection: { type: String, default: '' },
      mode: { type: String, required: true },
    },
    emits: ['close-popup'],
    setup(props, context) {
      const { getters: cmsGetters } = useCMSStore()
      function close() {
        context.emit('close-popup')
      }
      const getImage = (index: number) => {
        switch (index) {
          case 0:
            return '/images/Peer.svg'
          case 1:
            return '/images/Timer.svg'
          case 2:
            return '/images/Mentor.svg'
        }
      }
      const getColor = (index: number) => {
        switch (index) {
          case 0:
            return 'background-color: #ffb24d;'
          case 1:
            return 'background-color: #ffef35;'
          case 2:
            return 'background-color: #44b9de;'
        }
      }
      return {
        close,
        getImage,
        getColor,
        reflections: computed(() => (props.reflection ? cmsGetters.reflections.value.get(props.reflection) : '')),
        PROJECT_NAME,
      }
    },
  })
</script>
<style scoped>
  .light {
    font-family: 'Inter-Light', 'Inter', sans-serif;
  }
  .para {
    color: var(--texte_grey_black_title, #0f0f0f);
    font-family: 'Inter-Light', 'Inter', sans-serif;
  }

  .text {
    font-family: 'Inter', sans-serif;
  }
  .subheader {
    font-family: 'Inter-Medium', 'Inter', sans-serif;
  }
  .questionItem {
    background-color: #645cff;
    font-family: 'Inter-Light', 'Inter', sans-serif;
  }
  .bulb {
    background-color: #9e98fc;
  }
</style>
