<template>
  <div class="bg-white" :class="switchBackgroundColor()"></div>
  <div v-if="dashboard" class="mx-24 pb-12">
    <div class="flex mx-12 space-x-8 text-sm">
      <div class="flex-none w-96 h-14">
        <div class="v4v relative text-lg flex flex-row cursor-pointer font-extralight hover:font-semibold" @click="showComponent(-1)">
          <div class="mt-4 text-lg">Verktøy for veiledning</div>
        </div>
      </div>
      <div class="grow h-14 mt-4">
        <div class="flex justify-end space-x-8">
          <div
            v-for="(tab, index) in dashboard.tabs.iv"
            :key="index"
            class="flex justify-end space-x-8 v4v text-lg cursor-pointer relative font-extralight hover:font-semibold"
            :class="show == 'leader' ? 'font-semibold' : 'font-extralight'"
            @click="showComponent(index)"
          >
            <p class="text-lg">{{ tab.heading }}</p>
          </div>
        </div>
      </div>
      <div class="flex-none w-14 h-14 ...">
        <div class="col-end-4 col-span-1 flex flex-row group mt-4">
          <div
            style="background-color: var(--green, #00cb8d)"
            class="cursor-pointer rounded-full h-11 w-11 flex items-center justify-center"
            @click="showDetails = !showDetails"
          >
            {{ thumbnail }}
          </div>
        </div>
      </div>
    </div>
    <div :class="!showDetails ? 'hidden' : ''" class="absolute top-20 right-12 cursor-pointer mb-8 h-5 text-sm rounded-lg">
      <p
        v-if="mode == PROJECT_NAME.simulator"
        class="cursor-pointer break-normal text-sm mx-1 left-0 top-0 px-2 rounded-lg hover:bg-sky-600 hover:text-white"
        @click="show = 'results'"
      >
        Dine tidligere resultater
      </p>
      <div class="cursor-pointer text-sm mx-1 left-0 top-0 px-2 rounded-lg hover:bg-sky-600 hover:text-white" @click="logout">Logg ut</div>
    </div>
    <div class="grid justify-self-center"><div class="grid justify-self-center lg:w-5/6 xl:w-3/6 frameTop h-60 mt-2"></div></div>
  </div>
  <div class="grid grid-rows-1 justify-items-center">
    <!--  center  header -->
    <div v-if="show == '' && showResult == false" class="lg:w-5/6 xl:w-3/6 flex flex-col justify-items-center">
      <!-- <div :class="showResult == false ? 'frameTop h-60 mt-2' : ''"></div> -->
      <div v-if="dashboard" class="heading font-semibold text-center text-4xl my-4">{{ dashboard.title }}</div>
      <div v-if="dashboard" class="text-center m-2 text text-lg">{{ dashboard.subtitle }}</div>
    </div>
    <div v-if="show == '' && showResult == false && mode !== PROJECT_NAME.guides" class="flex items-end flex-row space-x-4">
      <SlButton class="mb-8 bg-blue-600 text-white justify-self-end text-sm leading-relaxed" style="font-family: 'Inter', sans-serif"
        ><span v-if="mode == PROJECT_NAME.simulator" @click="show = 'test'">Start simulatoren </span>
        <router-link v-else-if="mode == PROJECT_NAME.survey" to="/survey/profile">Innhent respons</router-link>
        <a v-else-if="mode == PROJECT_NAME.videotool" href="https://www.uv.uio.no/ils/forskning/prosjekter/viva/">VIVA </a>
      </SlButton>
    </div>
  </div>
  <!-- Frame 2  -->
  <div
    v-if="show == '' && showResult == false && dashboard"
    class="grid grid-rows-1 justify-items-center"
    style="background-color: var(--BACKGROUND_OK, #f5f5f7)"
  >
    <div
      v-for="(tab, index) in dashboard.tabs.iv"
      :key="index"
      class="flex flex-row text-left bg-white lg:w-5/6 xl:w-2/6 rounded-lg my-12 grid grid-cols-2"
      :class="show && show == 'leader' ? 'font-semibold' : 'font-extralight'"
      @click="showComponent(index)"
    >
      <div class="m-3">
        <p class="mt-6 text-xl text-left lg:text-3xl para text-black">{{ tab.heading }}</p>
        <p class="mt-4 para text-left text-gray-700 text-base leading-relaxed">
          {{ tab.subHeading }}
        </p>
        <SlButton
          class="buttonbackgroundColor h-8 mt-5 w-28 focus:outline-none btn_text text-white text-sm"
          :custom-width="'8rem'"
          @click="show = 'leader'"
        >
          Les mer
        </SlButton>
      </div>
      <div :class="index == 0 ? `` : 'bulb'" class="grid justify-self-end w-5/6 rounded-r-lg">
        <img v-if="index == 0" class="h-full rounded-r-lg w-full" src="@/assets/Leadership.png" />
        <img v-if="index == 1" class="justify-self-center h-48 my-6 mt-16 rounded-r-lg" src="@/assets/lightball2.svg" />
      </div>
    </div>
  </div>
  <Leaderships
    v-if="dashboard && show == 'leader'"
    class="p-8 justify-self-center"
    :mode="mode"
    :title="dashboard.tabs.iv[0]"
    @update-show="updateShow"
    @close-popup="closePopup()"
  ></Leaderships>
  <Reflections
    v-if="dashboard && show == 'reflection'"
    :mode="mode"
    class="justify-self-center p-8"
    :title="dashboard.tabs.iv[1]"
    @close-popup="closePopup()"
  ></Reflections>
  <div v-if="show == '' && showResult == false" class="grid grid-rows-1 justify-items-center">
    <div v-if="dashboard" class="w-5/6 mt-4">
      <p v-html="dashboard.notes"></p>
    </div>
  </div>
  <SimulationTest v-if="show == 'test'"></SimulationTest>
  <SimulationResult v-if="showResult && show !== 'results'" class="" @update-show="updateShow"></SimulationResult>
  <div v-if="show == 'results'" id="results" class="grid grid-rows-1 justify-items-center bg-white">
    <div class="lg:w-5/6 xl:w-3/6 text-left">
      <p class="text-left textExtraLight leading-relaxed font-extralight text-3xl text-left">Tidligere resulater</p>
    </div>
    <div v-for="(result, index) in results" :key="index" class="lg:w-5/6 xl:w-3/6 flex flex-col justify-items-center">
      <div class="flex justify-between border-t-2 py-6 px-4">
        <span class="textExtraLight font-light text-xl">ID :{{ result.oid }} </span>
        <div class="text-left textLight text-sm font-light">
          <p>Result from:</p>
          <p class="font-normal text-sm">{{ result.created }}</p>
        </div>

        <div class="px-10 flex space-x-4">
          <div
            class="h-10 w-32 leading-relaxed border flex items-center justify-center text-base border-gray-400 rounded-3xl text-white text-base cursor-pointer opacity-50 hover:opacity-100"
            style="background-color: #ff9d20; font-family: 'Inter', sans-serif; color: #7a7a7a"
            @click="showProfile(result)"
          >
            <p>Se på respons</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent, ref, onMounted } from 'vue'
  // import { useCMSStore } from '../store/useCMSStore'
  import router from '@/router'
  // import { useProjectStore } from '@/store/useProjectStore'
  import SlButton from '@/components/base/SlButton.vue'

  import { useUserStore } from '@/store/useUserStore'
  import { useCMSStore } from '@/store/useCMSStore'
  import { useAppStore } from '@/store/useAppStore'
  import { SimulatorResult } from '@/types/resultModel'
  import { TRACKING_TYPE, SPECIAL_REQUEST_TYPE, Tracking, XHR_REQUEST_TYPE } from '@/types/main'
  import Leaderships from '@/components/LeadershipsList.vue'
  import Reflections from '@/components/ReflectionList.vue'
  import SimulationResult from '@/components/SimulationResult.vue'
  import SimulationTest from '@/components/SimulationTest.vue'
  import html2canvas from 'html2canvas'
  import { PROJECT_NAME } from '@/constants'
  import { apiRequest } from '@/api/apiRequest'
  export default defineComponent({
    name: 'ToolView',
    components: { SlButton, Leaderships, Reflections, SimulationTest, SimulationResult },
    setup() {
      const { getters: userGetters, actions: userActions } = useUserStore()
      const { getters: cmsGetters, actions: cmsActions } = useCMSStore()

      const rememberMe = ref(false)
      const show = ref('')
      const showDetails = ref(false)
      const { getters: appGetters, actions: appActions } = useAppStore()
      const mode: PROJECT_NAME = PROJECT_NAME[appGetters.selectedToolMode.value as keyof typeof PROJECT_NAME]

      const switchBackgroundColor = () => {
        if (show.value == 'test') return 'testBackground'
        else if (show.value == 'reflection') return 'reflectionBackground'
        else return 'bg-white'
      }
      const showComponent = (tabIndex: number) => {
        userActions.clearResult().then(() => {
          if (tabIndex == -1) {
            show.value = ''
            // Clear leaderships and reflections
            router.push('/dashboard')
          } else {
            tabIndex == 0 ? (show.value = 'leader') : (show.value = 'reflection')
          }
        })
      }
      const updateShow = (mode: string) => {
        if (mode) show.value = mode
        else show.value = 'test'
      }

      // Screen shot
      const capture = () => {
        const resultProfile = document.getElementById('graph')
        if (resultProfile instanceof HTMLElement) {
          html2canvas(resultProfile, {
            backgroundColor: 'white',
          }).then(function (canvas) {
            saveAs(canvas.toDataURL(), 'Network.png')
          })
        }

        function saveAs(uri: string, filename: string) {
          const link = document.createElement('a')

          if (typeof link.download === 'string') {
            link.href = uri
            link.download = filename

            //Firefox requires the link to be in the body
            document.body.appendChild(link)
            link.click()

            //remove the link when done
            document.body.removeChild(link)
          } else {
            window.open(uri)
          }
        }
      }
      const showProfile = (result: Tracking) => {
        const r1 = result as SimulatorResult
        if (r1.oid) {
          userActions.setSelectedTracking(r1).then(() => (show.value = ''))
        }
        return true
      }
      const logout = () => {
        const completeLogout = () => {
          appActions.logout(rememberMe.value)
          router.push('/')
          // const admin = userGetters.myUser.value.profile.role === 'admin' ? 'admin' : ''
          // router.push(`/${admin}`)
        }
        apiRequest({
          route: '/auth/logout',
          method: XHR_REQUEST_TYPE.GET,
          credentials: true,
        })
          .then(() => {
            completeLogout()
          })
          .catch((error) => {
            // Exchange was not accepted, clear the token and redirect to login page
            console.log(error)
            completeLogout()
          })
      }
      onMounted(() => {
        //Automatically select the user as a participant
        if (cmsActions && mode) {
          cmsActions
            .setselectedProject(mode)
            .then(() => {
              Promise.all([cmsActions.getDashboardContents(), cmsActions.getReflections(), cmsActions.getLeaderships()]).then(() => {
                if (mode == PROJECT_NAME.simulator) {
                  Promise.all([cmsActions.getItem()]).then(() => {
                    cmsActions.selectItem('0')
                    // Fetch previous results based on Participant ID
                    userActions.getSpecialRequestV4V(userGetters.selectedUser.value._id, '', SPECIAL_REQUEST_TYPE.successresults)
                  })
                }
              })
            })
            .catch((err) => {
              console.log(err)
            })
        }
        // })
      })
      const closePopup = () => {
        show.value = ''
      }
      const scroll = () => {
        if (show.value == 'results') {
          const ele = document.getElementById('results')
          if (ele !== null) ele.scrollIntoView()
        }
      }
      return {
        items: cmsGetters.items,
        results: userGetters.trackings,
        showResult: userGetters.showResult,
        dashboard: cmsGetters.dashboard,
        show,
        mode,
        logout,
        // // Methods
        // forward,

        // info,
        closePopup,
        scroll,
        showDetails,
        switchBackgroundColor,
        updateShow,
        showProfile,
        PROJECT_NAME,
        capture,
        showComponent,
        thumbnail: userGetters.selectedUser.value.profile.fullName
          ? userGetters.selectedUser.value.profile.fullName
              ?.match(/(\b\S)?/g)
              ?.join('')
              ?.toUpperCase()
          : '',
      }
    },
  })
</script>
<style>
  .frame {
    background-image: url('../../assets/Top.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .frameTop {
    background-image: url('../../assets/Top.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .v4v {
    font-weight: 300;
    font-family: 'Inter-Light', 'Inter', sans-serif;
    color: #000000;
    font-size: 23px;
    letter-spacing: 0px;
    line-height: 1.7;
  }
  .bulb {
    background-color: #ecebff;
  }

  .subheader {
    letter-spacing: 0px;
    color: var(--texte_grey_black_title, #0f0f0f);
    font-family: 'Inter-SemiBold', 'Inter', sans-serif;
  }

  .para {
    font-family: 'Inter', sans-serif;
    /* color: #8a8a8a; */
  }
  .textExtraLight {
    font-family: 'Inter-ExtraLight', 'Inter', sans-serif;
  }
  .btn_text {
    font-family: 'Inter-Medium', 'Inter', sans-serif;
  }
  .ref {
    line-height: 1.4;
    color: var(--texte_grey_black_title, #0f0f0f);
    font-weight: 300;
    font-family: 'Inter-Light', 'Inter', sans-serif;
  }

  .testBackground {
    background-color: #ffd1c2;
  }
  .reflectionBackground {
    background-color: #ecebff;
  }
  .buttonbackgroundColor {
    background-color: var(--Canvas_Bluelight, #1a6899);
  }
  .icon {
    background-image: url('../../assets/classim_icon.svg');
    background-repeat: no-repeat;
  }
</style>
