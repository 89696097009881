/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import { BrowserLanguageCodes, nettskjemaUrl } from '@/constants'
import { uuid } from '@/utilities'
import moment from 'moment'
// ---------------  Utility -----------------
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    WkWebView: any
    handleOpenURL: unknown
  }
  interface String {
    toPascalCase(): string
    toCamelCase(): string
    padZero(length: number): string
  }
  interface MediaFile {
    localURL: string
  }
}
String.prototype.toPascalCase = function () {
  const text = this.valueOf().replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''))
  return text.substr(0, 1).toUpperCase() + text.substr(1)
}

String.prototype.toCamelCase = function () {
  const text = this.valueOf().replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''))
  return text.substr(0, 1).toLowerCase() + text.substr(1)
}

String.prototype.padZero = function (length: number): string {
  let s = String(this)
  while (s.length < length) {
    s = '0' + s
  }
  return s
}

export interface ColumnDef {
  headerName: string
  field: string

  children?: {
    field: string
    headerName: string
    columnGroupShow?: string
    // eslint-disable-next-line
    [x: string]: any
  }[]
  hide?: boolean | unknown
  editable?: boolean | unknown
  // eslint-disable-next-line
  [x: string]: any
}
interface Dictionary<T> {
  [Key: string]: T
}

type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U
}

export type { Dictionary, EnumDictionary }
export interface responseData {
  studentsResponse: Record<string, unknown>
  teachersResponse: Record<string, unknown>
}
export interface LottieOptions {
  loop?: boolean
  autoplay?: boolean
  // eslint-disable-next-line
  animationData?: string | object
  path?: string
  src?: string
  rendererSettings?: {
    preserveAspectRatio: boolean
    clearCanvas: boolean
    progressiveLoad: boolean
    hideOnTransparent: boolean
  }
}

// ---------------  Models -----------------

export interface CallbackOneParam<T, U = void> {
  (arg?: T): U
}
export interface Callback {
  (...args: unknown[]): unknown
}

// This defined the additional functions available on a Question Type component
// This allows Question.vue to control the question type child
// The child should implement appropriate code that runs when these functions are called
/* export interface AugmentedQuestionType extends Vue {
  forwardInternal: () => void // Called when the user clicks the white 'forward' arrow
  onIntroductionStart: () => void // Called when introduction begins
  onIntroductionEnd: () => void // Called when introduction ends
} */

export interface LocalUser extends Record<string, unknown> {
  _id: string
  jwt: string
  lastLogin: Date
  pin: string
  name: string
  selected: boolean
}
// General App settings that should be saved to disk
export interface PersistedAppState extends Record<string, unknown> {
  localUsers: Record<string, LocalUser>
}

export interface DialogConfig {
  title: string
  text: string
  visible: boolean
  confirm: Callback
  confirmText: string
  cancel: Callback
  cancelText: string
}
export interface SpecialRequestDataV4V {
  data1: Array<{ group: string; value: number }>
  data2: Array<{ group: string; value: number }>
  results: Array<Tracking>
}

export interface TrackingData {
  itemID: string // ID of the associated question or picturebook etc. (from Squidex CMS)
  userID: string // ID of the associated Participant
  projectID: string // ID of the associated Project
  type: string

  // All other items are optional
  oid?: string // Unique key used to map this item
  created?: Date | string // should mark the start of the tracking
  duration?: number // should mark the end of the tracking in seconds, starting from 'created'
  audioFile?: string
  videoFile?: string
  details?: Record<string, unknown> // Holds any kind of extra data needed for the Tracking

  localSynced?: boolean // saved to disk locally
  serverSynced?: boolean // saved to our server
  storageSynced?: boolean // saved to TSD
}
export class Tracking {
  itemID: string // ID of the associated question, set or picturebook etc. (from Squidex CMS)
  userID: string // ID of the associated Participant
  projectID: string // ID of the associated Project
  type: TRACKING_TYPE

  oid: string // Unique key used to map this item
  created?: Date | string // the start of the tracking
  duration: number // should mark the end of the tracking in seconds, starting from 'created'
  audioFile?: string
  videoFile?: string
  details?: Record<string, unknown> // Holds any kind of data specific to the question type

  // Status
  localSynced: boolean // saved to disk locally
  serverSynced: boolean // saved to our server successfully
  storageSynced: boolean // sent to TSD successfully

  constructor(trackingdata: TrackingData) {
    this.itemID = trackingdata.itemID
    this.userID = trackingdata.userID
    this.projectID = trackingdata.projectID
    this.type = trackingdata.type as TRACKING_TYPE
    this.oid = trackingdata.oid ? trackingdata.oid : uuid()
    this.created = trackingdata.created ? new Date(trackingdata.created) : new Date()
    this.duration = trackingdata.duration ? trackingdata.duration : 0

    // Optional
    this.audioFile = trackingdata.audioFile
    this.videoFile = trackingdata.videoFile

    this.oid = trackingdata.oid ? trackingdata.oid : uuid()
    this.created = trackingdata.created ? new Date(trackingdata.created) : new Date()

    this.localSynced = !!trackingdata.localSynced
    this.serverSynced = !!trackingdata.serverSynced
    this.storageSynced = !!trackingdata.storageSynced
  }

  // Complete this Tracking by setting its duration and possibly 'data'
  complete(): void {
    const startDate = moment(this.created)
    const endDate = moment()
    this.duration = endDate.diff(startDate, 'seconds')
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): unknown {
    const pojo = { ...this }
    return pojo
  }
}

export class SimulatorResult extends Tracking {
  details: Record<string, string>
  constructor(trackingdata: SimulatorResultData) {
    super(trackingdata)
    this.details = {}
    if (trackingdata.details) {
      this.details = trackingdata.details
    }
  }
}
export interface DataPath {
  itemId: string
  value: string
}

export interface SimulatorResultData extends TrackingData {
  details?: {
    dataType: string
  }
}
export enum SPECIAL_REQUEST_TYPE {
  successresults = 'successresults',
  aggregate = 'aggregate',
}
export enum TRACKING_TYPE {
  interaction = 'interaction',
  all = 'all',
  v4v = 'v4v',
  previousResults = 'previousResults',
}
export interface URESPONSE {
  count: number
  timeStamp: Date
}
export interface codeData {
  code: number
  name: string
  SUri: string
  TUri: string
  Tresponse: URESPONSE
  Sresponse: URESPONSE
}
// ----------  Squidex response shapes --------------

// Data level of a Squidex GraphQL response. Can be supplied as single or array
// Extend this interface to represent different responses for various Sett and Question types
export interface CmsGQLData {
  __typename: string
  id?: string
  flatData?: Record<string, unknown>
  data?: Record<string, unknown>
}
// Shape of the Sett -> Question response
export interface CmsQuestionData extends CmsGQLData {
  flatData: {
    questions: CmsGQLData[]
  }
}

// Top level of a Squidex GrapghQL response
export interface CmsGQLQuery {
  data?: {
    results: CmsGQLData[] | CmsGQLData | CmsQuestionData
    items?: CmsGQLData[]
    leaderships?: CmsGQLData[]
    reflections?: CmsGQLData[]
    dashboard?: CmsGQLData[]
    privacy?: CmsGQLData[]
    landing?: CmsGQLData[]
  }
  errors?: []
  access_token?: string
}

// ---------------  User -----------------
// export interface GroupData {
//   _id: string
//   name: string
//   location: string
// }
/*export class Group {
  _id: string
  name: string
  location: string

  constructor(data?: GroupData | Group) {
    this._id = ''
    this.name = ''
    this.location = ''
    if (data) this.update(data)
  }

  update(data: GroupData | Group): void {
    this._id = data._id
    this.name = data.name
    this.location = data.location
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): unknown {
    return { _id: this._id, name: this.name, location: this.location }
  }
}*/

export interface UserData {
  _id: string
  verified?: Date
  lastLogin?: Date
  message?: string
  // lastReminder?: Date

  profile: {
    username: string
    fullName: string
    email: string
    language: string
    organization: string
  }
  codes: codeData[] //survey tool codes
  progress: {
    //simulator tool progress
    lastAdjustedByAdmin: Date | undefined
    // progress is referenced by ID of the data model involved (e.g. question ID)
    records: Map<string, Progress>
  }
  // DB IDs of related Models
}
interface ProgressData {
  itemId: string
  parentId: string
  completed?: boolean
  completions?: string[] | Date[]
  attempts?: string[] | Date[]
}
export class Progress {
  itemId: string // CMS ID of the tracked item
  parentId: string // CMS ID of the current parent of this item
  completed = false
  completions: Date[] = [] // Completions marked only if item was not previously completed
  attempts: Date[] = [] // Attempts on this item (increments if already completed, may be larger than completions[])

  constructor(data: ProgressData) {
    this.itemId = data.itemId
    this.parentId = data.parentId
    this.completed = !!data.completed
    if (data.parentId) this.parentId = data.parentId
    if (data.completions && data.completions.length > 0) {
      data.completions.forEach((cp: string | Date) => {
        this.completions.push(new Date(cp))
      })
    }
    if (data.attempts && data.attempts.length > 0) {
      data.attempts.forEach((cp: string | Date) => {
        this.attempts.push(new Date(cp))
      })
    }
  }

  // Return the most recent completion
  get latestCompletion(): Date {
    return this.completions[this.completions.length - 1]
  }

  // Return the most recent attempt
  get latestAttempt(): Date {
    return this.attempts[this.attempts.length - 1]
  }

  // Set this Progress to be 'completed'
  // Add a new timestamp for this completion
  // Returns the total current number of completions
  complete(): number {
    const newDate = new Date()
    if (!this.completed) {
      this.completed = true
      this.completions.push(newDate)
    }
    this.attempts.push(newDate)
    return this.completions.length
  }

  // // Convert this to a Plain Old Javascript Object
  asPOJO(): ProgressData {
    const pojo = { ...this }
    return pojo
  }
  // Convert this to a Plain Old Javascript Object
  //   asPOJO(): unknown {
  //     const records: Record<string, ProgressData> = {}
  //     if (this.progress.records) {
  //       const progressArray = Array.from(this.progress.records.entries())
  //       progressArray.forEach((p) => {
  //         const [key, prog] = p
  //         records[key] = prog.asPOJO()
  //       })
  //     }
  //     const progress = {
  //       records,
  //       lastAdjustedByAdmin: this.progress.lastAdjustedByAdmin,
  //     }
  //     const pojo = { ...this, progress }
  //     return pojo
  //   }
}
export class User {
  _id: string
  verified?: Date
  lastLogin?: Date
  message?: string
  // lastReminder?: Date

  profile: {
    username: string
    fullName: string
    email: string
    language: BrowserLanguageCodes // Use a two letter code as the browser does
    organization: string // Organization to which user is belongs to
  }
  codes: codeData[] = [] //survey tool codes
  progress: {
    //simulator tool progress
    lastAdjustedByAdmin: Date | undefined
    // progress is referenced by ID of the data model involved (e.g. question ID)
    records: Map<string, Progress>
  }
  constructor(data?: UserData | User) {
    this._id = ''

    this.profile = {
      username: 'initial user',
      fullName: 'initial user',
      email: '',
      language: BrowserLanguageCodes.no, // Use a two letter code as the browser does
      organization: '',
    }
    this.progress = {
      records: new Map(),
      lastAdjustedByAdmin: undefined,
    }
    if (data) this.update(data)
  }

  // PRIVATE member to update progress class attribute
  // using 'private' keyword causes problems with TS compile..
  updateProgress(data: UserData | User): void {
    if (data.progress) {
      this.progress.lastAdjustedByAdmin = data.progress.lastAdjustedByAdmin ? new Date(data.progress.lastAdjustedByAdmin) : undefined
      const dProgress = data.progress.records
      if (data.progress.records) {
        if (data instanceof User) {
          this.progress.records = data.progress.records
        } else {
          if (typeof dProgress === 'object') {
            console.log(dProgress)
            const pProgress = new Map(Object.entries(data.progress.records))
            for (const pKey in pProgress) {
              if (pProgress.has(pKey)) {
                const d = pProgress.get(pKey)
                if (d) this.progress.records.set(pKey, new Progress(d))
              }
            }
          }
        }
      }
    }
  }

  public update(data: UserData | User): void {
    this._id = data._id
    this.verified = data.verified
    this.lastLogin = data.lastLogin ? new Date(data.lastLogin) : undefined
    this.message = data.message || ''

    this.profile = {
      username: data.profile.username || '',
      fullName: data.profile.fullName || '',
      email: data.profile.email || '',
      language: (data.profile.language as BrowserLanguageCodes) || BrowserLanguageCodes.no,
      organization: data.profile.organization || '',
    }
    this.codes = data.codes ? data.codes : []
    // data.codes.forEach((code) => {
    //     this.codes.push({
    //       code: code.code,
    //       name: code.name,
    //       SUri: `${nettskjemaUrl}160170?CBcode=${code.code}`,
    //       TUri: `${nettskjemaUrl}160171?CBcode=${code.code}`,

    //       Sresponse: {
    //         count: 0,
    //         timeStamp: new Date(),
    //       },
    //       Tresponse: { count: 0, timeStamp: new Date() },
    //     })
    //   })
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): unknown {
    const records: Record<string, ProgressData> = {}
    if (this.progress.records) {
      const progressArray = Array.from(this.progress.records.entries())
      progressArray.forEach((p) => {
        const [key, prog] = p
        records[key] = prog.asPOJO()
      })
    }
    const progress = {
      records,
      lastAdjustedByAdmin: this.progress.lastAdjustedByAdmin,
    }
    const pojo = { ...this, progress }
    return pojo
  }
  // Get or create a Progress, and return it
  // itemId: the CMS ID of a Sett or Question
  // returns: Current number of attempts at this item
  createProgress(itemId: string, parentId: string): Progress {
    let p: Progress
    const id = itemId + (parentId ? ':' + parentId : '')
    if (this.progress.records.has(id)) {
      p = this.progress.records.get(id) as Progress
    } else {
      p = new Progress({ itemId, parentId })
      this.progress.records.set(id, p)
    }
    return p
  }
  // Get a Progress item and mark as completed
  // itemId: the CMS ID of a Sett or Question
  // returns: Current number of attempts at this item
  completeProgress(itemId: string, parentId: string): number {
    const p = this.createProgress(itemId, parentId)
    return p.complete()
  }
  // TODO:update response from Nettskjema
  public updateResponse(allCodes: responseData): void {
    this.codes.forEach((code) => {
      code.SUri = `${nettskjemaUrl}160170?CBcode=${code.code}`
      code.TUri = `${nettskjemaUrl}160171?CBcode=${code.code}`

      // for (const [key, value] of Object.entries(allCodes.studentsResponse)) {
      //   if (code.code == parseInt(key)) code.Sresponse.count = value as number
      // }
      // for (const [key, value] of Object.entries(allCodes.teachersResponse)) {
      //   if (code.code == parseInt(key)) code.Tresponse.count = value as number
      // }
      if (allCodes.studentsResponse && allCodes.studentsResponse[code.code]) {
        code.Sresponse = allCodes.studentsResponse[code.code] as URESPONSE
      }
      if (allCodes.teachersResponse && allCodes.teachersResponse[code.code]) {
        code.Tresponse = allCodes.teachersResponse[code.code] as URESPONSE
      }
    })
  }
}

// ---------------  API -----------------

enum XHR_REQUEST_TYPE {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
}
interface XHRError {
  status: number
}

enum XHR_CONTENT_TYPE {
  JSON = 'application/json',
  MULTIPART = 'multipart/form-data',
  URLENCODED = 'application/x-www-form-urlencoded',
}

// Augment the Error class with message and status
class HttpException extends Error {
  status: number
  message: string
  constructor(status: number, message: string) {
    super(message)
    this.status = status
    this.message = message
  }
}

interface APIRequestPayload {
  method: XHR_REQUEST_TYPE
  route: string
  credentials?: boolean
  body?: unknown | string | User | FormData | UserData // | <Other Model Type>
  headers?: Record<string, string>
  query?: Record<string, string>
  contentType?: string
  baseURL?: string
}

interface XHRPayload {
  url: string
  headers: Record<string, string>
  credentials: boolean
  body: string | FormData
  method: XHR_REQUEST_TYPE
}
// ------------ Cordova interface -------------

export interface CordovaDataType {
  readFile?: boolean // Returns the content if true, returns a FileEntry if false  (read)
  asText?: boolean // false   Set to true if reading a text or JSON file, otherwise binary will be used  (read/write)
  asJSON?: boolean // true   Set to false to read/write a file without parsing or stringifying JSON  (read/write)
  overwrite?: boolean // false   Set to true to overwrite an existing file (open file)
  append?: boolean // false   Set to true to append data to the end of the file (write)
  path?: string[] // Path to the file below the root as an array of directory names (read/write)
  fileName?: string // name of the file on disk (read/write)
  data?: unknown | Blob | string // the content to be written  (write)
  file?: FileEntry // the FileEntry object in memory
  fileToMove?: FileEntry | MediaFile // the file entry object in momory for the file to be moved
}
export class CordovaData {
  readFile = false
  asText = false
  asJSON = true
  overwrite = false
  append = false
  path: string[]
  fileName = ''
  data?: unknown | string | Blob
  file?: FileEntry
  fileToMove?: /* data */ FileEntry | MediaFile /* video */

  constructor(data: CordovaDataType) {
    this.path = []
    if (data) {
      this.readFile = data.readFile ? data.readFile : false
      this.asText = data.asText ? data.asText : false
      this.asJSON = data.asJSON ? data.asJSON : true
      this.overwrite = data.overwrite ? data.overwrite : false
      this.append = data.append ? data.append : false
      this.path = data.path ? data.path : []
      this.fileName = data.fileName ? data.fileName : ''
      this.data = data.data
      this.file = data.file
      this.fileToMove = data.fileToMove
    }
  }
}

export type { APIRequestPayload, XHRPayload, XHRError }
export { XHR_REQUEST_TYPE, HttpException, XHR_CONTENT_TYPE }
