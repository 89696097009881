interface AnswerOptions {
  answerOptionId: number
  correct: boolean
  externalAnswerOptionId: string
  preselected: boolean
  sequence: number
  text: string
}

interface Answer {
  answerId: number
  answerOptions: Array<AnswerOptions>
  externalQuestionId: string
  questionId: number
  textAnswer?: string
}

interface Question {
  description: string
  elementType: string
  externalQuestionId?: string
  questionId: number
  sequence: number
  text: string
}

interface Form {
  title?: string
  elementId: number
  description?: string
  elementType: string
  questions: Array<Question>
  sequence: number
}

export interface ReviewForm {
  title: string
  elements: Array<Form>
  answers: Array<Array<Answer>>
}

export interface Nettskjema {
  studentsReview: ReviewForm
  teachersReview: ReviewForm
}

// /* Classes */
// class QuestionReview {
//   questionText: string
//   average: number

//   constructor(spec: Form) {
//     this.questionText = spec.title || ''
//     spec.questions.forEach((q) => {
//       console.log(q)
//     })
//     this.average = 0
//   }
// }

// class QuestionMapEntry {
//   title: string
//   questionIds: Array<number>

//   constructor(spec: Form) {
//     this.title = spec.title || ''
//     this.questionIds = spec.questions.map((elem) => elem.questionId)
//   }
// }

enum ElementType {
  MATRIX_RADIO = 'MATRIX_RADIO',
}

export class Reviews {
  title: string
  review: Map<string, number>
  constructor(spec: ReviewForm) {
    this.title = spec.title
    const questionDictionary: Map<number, string> = new Map()
    this.review = new Map()
    // Create a dictionary for questions
    spec.elements.forEach((form) => {
      if (form.questions) {
        form.questions.forEach((elem) => {
          if (elem.elementType === ElementType.MATRIX_RADIO && form.title) questionDictionary.set(elem.questionId, form.title)
        })
      }
    })

    // Temporarily dictionaries to count and question IDs
    const total: Record<number, number> = {}
    const counter: Record<number, number> = {}
    spec.answers.forEach((userAnswers) => {
      userAnswers.forEach((answer) => {
        if (answer.answerOptions) {
          const id = answer.questionId
          const num = Number(answer.answerOptions[0].text[0])
          if (total[id]) {
            total[id] += num
            counter[id] += 1
          } else {
            total[id] = num
            counter[id] = 1
          }
        }
      })
    })

    // Connect the keys and add the average
    Object.keys(total).forEach((key) => {
      const nKey = Number(key)
      const average = Number(Math.floor(total[nKey] / counter[nKey]).toFixed(2))
      const text = questionDictionary.get(nKey)
      if (text) this.review.set(text, average)
    })
  }
}
