import { Tracking, TrackingData } from '@/types/main'

export interface DataPath {
  itemId: string
  value: string
}

export interface SimulatorResultData extends TrackingData {
  details?: {
    dataType: string
  }
}

export class SimulatorResult extends Tracking {
  details: Record<string, string>
  constructor(trackingdata: SimulatorResultData) {
    super(trackingdata)
    this.details = {}
    if (trackingdata.details) {
      this.details = trackingdata.details
    }
  }
}
